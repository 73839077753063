import { execute, makePromise } from 'apollo-link';
import { HttpLink } from 'apollo-link-http';
import { Cookies } from 'react-cookie';
import { createUploadLink } from 'apollo-upload-client';

const cookies = new Cookies();

const getToken = () => cookies.get('token');

export const BASE_URL = `${process.env.REACT_APP_API_HOST}api`;

const link = new HttpLink({ uri: BASE_URL });

const linkFile = createUploadLink({ uri: BASE_URL });

export const call = ({ query, variables, operationName, extensions }) => {
  const operation = {
    query,
    variables,
    operationName,
    context: {
      headers: {
        authorization: getToken(),
      },
    },
    extensions,
  };

  return makePromise(execute(link, operation));
};

export const callFile = ({ query, variables, operationName, extensions }) => {
  const operation = {
    query,
    variables,
    operationName,
    context: {
      headers: {
        authorization: getToken(),
      },
    },
    extensions,
  };

  return makePromise(execute(linkFile, operation));
};

export const restCall = async ({ method, query }) => {
  const results = await fetch(`${BASE_URL}${query}`, {
    method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getToken()}`,
    },
  });

  return results.text();
};
