import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import * as R from 'ramda';
import { withCookies } from 'react-cookie';

import { BASE_URL } from '../../../../utils/api';

const states = {
  CREATED: 's-circle bg-error',
  IN_PROGRESS: 's-circle bg-warning',
  COMPLETED: 's-circle bg-success',
  ERROR: 's-circle bg-error',
};

const Imports = ({
  imports,
  cookies,
  handlePrevious,
  handleNext,
  page,
  itemsOnPage,
}) => {
  const data = R.compose(
    R.map(R.values),
    R.map(R.pick(['date', 'filename', 'uid', 'state'])),
  )(imports);

  const getRow = (content, i) => {
    switch (i) {
      case 0:
        return dayjs.unix(content).format('DD/MM/YYYY - HH:mm');

      case 1:
        return <div>{content}</div>;

      case 2:
        return (
          <a
            href={`${BASE_URL}/uploads/import-ratings/${content}/import-ratings.log?token=${cookies.get(
              'token',
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="ml-1"
          >
            logs
          </a>
        );

      case 3:
        return (
          <div
            className={states[content]}
            style={{ height: '0.5rem', width: '0.5rem' }}
          />
        );

      default:
        return <div />;
    }
  };

  return (
    <Fragment>
      <div className="has-icon-right float-right" style={{ width: '20rem' }} />
      <table className="table">
        <thead>
          <tr>
            <th>date</th>
            <th>fichier</th>
            <th>logs</th>
            <th>état</th>
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr key={`item-data-${item[0]}`} className="active">
              {item.map((content, i) => (
                <td key={`item-content-${content}`}>{getRow(content, i)}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <button
          type="button"
          className="btn btn-action btn-link btn-lg"
          onClick={handlePrevious}
          disabled={page === 1}
        >
          <i className="icon icon-arrow-left" />
        </button>
        <button
          type="button"
          className="btn btn-action btn-link btn-lg"
          onClick={handleNext}
          disabled={imports.length < itemsOnPage}
        >
          <i className="icon icon-arrow-right" />
        </button>
      </div>
    </Fragment>
  );
};

Imports.propTypes = {
  imports: PropTypes.array.isRequired,
  cookies: PropTypes.object.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  itemsOnPage: PropTypes.number.isRequired,
};

export default withCookies(Imports);
