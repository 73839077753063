import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';
import dayjs from 'dayjs';

import history from '../../../routes/history';
import { getCredentials } from '../../../requests/users';

import { getXlsxWorksheets } from '../../../utils/fileParser';
import { importRatingsFile, listImports } from '../../../requests/imports';
import { listReviewers } from '../../../requests/reviewers';
import Autocomplete from '../../../theme/components/autocomplete';
import Imports from './imports';

const initialState = {
  credentials: undefined,
  file: undefined,
  reviewer: undefined,
  worksheets: [],
  header: [],
  body: [],
  startingLine: 0,
  hasHeader: false,
  suggestions: [],
  importDone: false,
  imports: [],
  minDate: dayjs('01/01/1970').unix(),
  maxDate: dayjs().unix(),
  page: 1,
  itemsOnPage: 10,
};

const reducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'setState':
      return { ...state, [payload.key]: payload.value };
    case 'resetState':
      return initialState;
    default:
      throw new Error();
  }
};

const Offers = ({ cookies }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    credentials,
    file,
    startingLine,
    hasHeader,
    suggestions,
    reviewer,
    importDone,
    imports,
    minDate,
    maxDate,
    page,
    itemsOnPage,
  } = state;

  const listImportsAction = async () => {
    const { data } = await listImports({
      minDate,
      maxDate,
      page,
      itemsOnPage,
      type: 'RATING',
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'imports',
        value: data.paginatedImports,
      },
    });
  };

  const getCredentialsAction = async () => {
    const { data, errors } = await getCredentials();

    if (errors) {
      cookies.remove('token');
      history.push('/signin');
    } else {
      dispatch({
        type: 'setState',
        payload: {
          key: 'credentials',
          value: data.me,
        },
      });
    }
  };

  useEffect(() => {
    getCredentialsAction();
  }, []);

  useEffect(() => {
    listImportsAction();
  }, [page]);

  const handleHasHeaderChange = () => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'hasHeader',
        value: !hasHeader,
      },
    });
  };

  const handleFileChange = async e => {
    const newFile = e.target.files[0];

    if (e.target.validity.valid) {
      dispatch({
        type: 'setState',
        payload: {
          key: 'file',
          value: newFile,
        },
      });

      const { worksheets: newWorksheets } = await getXlsxWorksheets(newFile);

      dispatch({
        type: 'setState',
        payload: {
          key: 'worksheets',
          value: newWorksheets,
        },
      });
    }
  };

  /**
   * Function handling the starting line input change
   */
  const handleStartingLineChange = e => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'startingLine',
        value: e.target.value,
      },
    });
  };

  /**
   * Function handling the import of the ratings
   */
  const handleRatingsImport = async () => {
    await importRatingsFile({
      userUid: credentials.uid,
      reviewerUid: reviewer.uid,
      file,
      hasHeader,
    }).catch(() => {
      return false;
    });

    dispatch({
      type: 'setState',
      payload: { key: 'importDone', value: true },
    });
  };

  /**
   * Function exectued on the autocomplete input change
   */
  const handleOnAutocompleteChange = async text => {
    const { data } = await listReviewers({ text });

    dispatch({
      type: 'setState',
      payload: {
        key: 'suggestions',
        value: data.searchReviewers,
      },
    });
  };

  /**
   * Function exectued when an autocomplete item is clicked
   */
  const handleOnAutocompleteClick = suggestion => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'reviewer',
        value: suggestion,
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'suggestions',
        value: [],
      },
    });
  };

  const resetStateAction = async () => {
    await dispatch({ type: 'resetState' });

    getCredentialsAction();
    listImportsAction();
  };

  /**
   * If no token in cookies, redirect to signin
   */
  if (!cookies.get('token')) {
    history.push('/signin');
    return '';
  }

  /**
   * Function to handle imports table previous
   */
  const handlePrevious = () => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'page',
        value: page - 1,
      },
    });
  };

  /**
   * Function to handle imports table next
   */
  const handleNext = () => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'page',
        value: page + 1,
      },
    });
  };

  if (importDone) {
    return (
      <div className="container">
        <div className="card">
          <div className="card-header">
            <div className="card-title h5">Import fait</div>
            <button
              type="button"
              className="btn btn-primary mt-2"
              onClick={resetStateAction}
            >
              Recharger
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="card">
        <div className="card-header">
          <div className="card-title h5">
            Choisissez un fichier excel ou csv
          </div>
        </div>
        <div className="card-body">
          <div className="h6 mb-2">
            Format : nom du vin | millésime | note min (ex : 90+) | note max (ex
            : 91-) | date format YYYY-MM-DD | commentaire
          </div>
          <div>
            Ligne de départ :{' '}
            <input
              type="number"
              value={startingLine}
              onChange={handleStartingLineChange}
            />
          </div>
          <div>
            <div className="form-group">
              <label className="form-switch" htmlFor="header-checkbox">
                <input
                  id="header-checkbox"
                  type="checkbox"
                  onChange={handleHasHeaderChange}
                  checked={hasHeader ? 'checked' : ''}
                />
                <i className="form-icon" /> header
              </label>
            </div>
          </div>
          <div className="mt-2 mb-2">
            <input type="file" required onChange={handleFileChange} />
          </div>
        </div>
      </div>
      <div className="card mt-2">
        <div className="card-header">
          <div className="card-title h5">
            Choisissez un reviewer puis importez votre fichier
          </div>
        </div>
        <div className="card-body">
          <div className="mt-2 mb-2">
            {reviewer ? (
              <p>{reviewer.name}</p>
            ) : (
              <Autocomplete
                onChange={handleOnAutocompleteChange}
                onClick={handleOnAutocompleteClick}
                formatValue={value => value.name}
                suggestions={suggestions}
              />
            )}
          </div>
        </div>
        <div className="ml-2 mb-2">
          <button
            type="button"
            className="btn btn-primary ml-2 mb-2"
            onClick={handleRatingsImport}
            disabled={!reviewer || !file}
          >
            Importer
          </button>
        </div>
      </div>
      <div className="card mt-2">
        <div className="card-body">
          <Imports
            imports={imports}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            page={page}
            itemsOnPage={itemsOnPage}
          />
        </div>
      </div>
    </div>
  );
};

Offers.propTypes = {
  cookies: PropTypes.object.isRequired,
};

export default withCookies(Offers);
