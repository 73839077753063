import React from 'react';
import { withCookies } from 'react-cookie';

import Labels from '../labels';

const Admin = () => {
  return (
    <div className="container">
      <div className="card">
        <div className="card-header">
          <div className="card-title h5">Labels</div>
        </div>
        <div className="card-body">
          <Labels />
        </div>
      </div>
    </div>
  );
};

export default withCookies(Admin);
