import gql from 'graphql-tag';

import { call } from '../../utils/api';

const listWineLabelsQuery = gql`
  query($page: Int!, $itemsOnPage: Int!, $name: String) {
    paginatedWineLabels(page: $page, itemsOnPage: $itemsOnPage, name: $name) {
      uid
      name
      wine {
        uid
        name
      }
    }
  }
`;

const listFormatsLabelsQuery = gql`
  query($page: Int!, $itemsOnPage: Int!, $name: String) {
    paginatedFormatLabels(page: $page, itemsOnPage: $itemsOnPage, name: $name) {
      uid
      name
      format {
        uid
        name
      }
    }
  }
`;

const listPackagingLabelsQuery = gql`
  query($page: Int!, $itemsOnPage: Int!, $name: String) {
    paginatedPackagingLabels(
      page: $page
      itemsOnPage: $itemsOnPage
      name: $name
    ) {
      uid
      name
      packaging {
        uid
        name
      }
    }
  }
`;

const listLabelsQuery = gql`
  query($page: Int!, $itemsOnPage: Int!) {
    paginatedLabels(page: $page, itemsOnPage: $itemsOnPage) {
      uid
      name
    }
  }
`;

const createLabelForWineQuery = gql`
  mutation($wineUid: String!, $labelName: String!) {
    createLabelForWine(wineUid: $wineUid, labelName: $labelName) {
      uid
      name
    }
  }
`;

const createLabelForFormatQuery = gql`
  mutation($formatUid: String!, $labelName: String!) {
    createLabelForFormat(formatUid: $formatUid, labelName: $labelName) {
      uid
      name
    }
  }
`;

const createLabelForPackagingQuery = gql`
  mutation($packagingUid: String!, $labelName: String!) {
    createLabelForPackaging(
      packagingUid: $packagingUid
      labelName: $labelName
    ) {
      uid
      name
    }
  }
`;

export const listWineLabels = variables =>
  call({ query: listWineLabelsQuery, variables });

export const listFormatLabels = variables =>
  call({ query: listFormatsLabelsQuery, variables });

export const listPackagingLabels = variables =>
  call({ query: listPackagingLabelsQuery, variables });

export const listLabels = variables =>
  call({ query: listLabelsQuery, variables });

export const createLabelForWine = variables =>
  call({ query: createLabelForWineQuery, variables });

export const createLabelForFormat = variables =>
  call({ query: createLabelForFormatQuery, variables });

export const createLabelForPackaging = variables =>
  call({ query: createLabelForPackagingQuery, variables });
