import gql from 'graphql-tag';

import { call } from '../../utils/api';

const listFieldsQuery = gql`
  {
    fields: allImportFields {
      uid
      code
      name
      type
      required
    }
  }
`;

export const listFields = () => call({ query: listFieldsQuery });
