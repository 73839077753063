import React, { useState } from 'react';
import PropTypes from 'prop-types';

const Profiles = ({ profiles, handleCreateProfile, handleSelectProfile }) => {
  const [name, setName] = useState('');

  const handleOnNameChange = e => {
    setName(e.target.value);
  };

  const handleOnCreateProfileClick = () => {
    handleCreateProfile(name);
  };

  const handleOnProfileClick = profile => () => {
    handleSelectProfile(profile);
  };

  return (
    <div>
      <div>
        {profiles.map(profile => (
          <button
            key={`profile-${profile.id || profile.uid}`}
            type="button"
            className="mb-2"
            style={{ width: '10%' }}
            onClick={handleOnProfileClick(profile)}
          >
            {profile.name}
          </button>
        ))}
      </div>
      <input value={name} onChange={handleOnNameChange} />
      <button
        type="button"
        onClick={handleOnCreateProfileClick}
        disabled={name.length === 0}
      >
        Créer un profile
      </button>
      <p>(récupère les champs actuels)</p>
    </div>
  );
};

Profiles.propTypes = {
  profiles: PropTypes.array.isRequired,
  handleCreateProfile: PropTypes.func.isRequired,
  handleSelectProfile: PropTypes.func.isRequired,
};

export default Profiles;
