import gql from 'graphql-tag';

import { call } from '../../utils/api';

const listReviewersQuery = gql`
  query searchReviewers($text: String!) {
    searchReviewers(text: $text) {
      uid
      name
    }
  }
`;

export const listReviewers = variables =>
  call({ query: listReviewersQuery, variables });
