import gql from 'graphql-tag';

import { call } from '../../utils/api';

const credentialsQuery = gql`
  {
    me
  }
`;

const signInQuery = gql`
  query login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

export const getCredentials = () => call({ query: credentialsQuery });

export const signIn = params => call({ query: signInQuery, variables: params });
