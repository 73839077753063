import gql from 'graphql-tag';

import { call, callFile, restCall } from '../../utils/api';

const importOffersFileMutation = gql`
  mutation uploadAndImportOffers(
    $userUid: String!
    $partnerUid: String!
    $fields: [String]!
    $file: Upload!
    $hasHeader: Boolean!
    $date: String!
    $complementary: Boolean
    $rateType: RateType!
  ) {
    uploadAndImportOffers(
      userUid: $userUid
      partnerUid: $partnerUid
      fields: $fields
      file: $file
      hasHeader: $hasHeader
      date: $date
      complementary: $complementary
      rateType: $rateType
    )
  }
`;

const importRatingsFileMutation = gql`
  mutation uploadAndImportRatings(
    $userUid: String!
    $reviewerUid: String!
    $file: Upload!
    $hasHeader: Boolean!
  ) {
    uploadAndImportRatings(
      userUid: $userUid
      reviewerUid: $reviewerUid
      file: $file
      hasHeader: $hasHeader
    )
  }
`;

const listImportsQuery = gql`
  query paginatedImports(
    $minDate: Int!
    $maxDate: Int!
    $page: Int!
    $itemsOnPage: Int!
    $type: ImportType
  ) {
    paginatedImports(
      minDate: $minDate
      maxDate: $maxDate
      page: $page
      itemsOnPage: $itemsOnPage
      type: $type
    ) {
      uid
      date
      createdAt
      state
      filename
    }
  }
`;

export const importOffersFile = variables =>
  callFile({ query: importOffersFileMutation, variables });

export const importRatingsFile = variables =>
  callFile({ query: importRatingsFileMutation, variables });

export const listImports = variables =>
  call({ query: listImportsQuery, variables });

export const getUnimportedCsv = uid =>
  restCall({
    method: 'GET',
    query: `/uploads/import/${uid}/unimported.csv`,
  });
