import gql from 'graphql-tag';

import { call } from '../../utils/api';

const listFormatsQuery = gql`
  query($text: String!) {
    filteredFormats(text: $text) {
      uid
      code
      name
    }
  }
`;

export const listFormats = variables =>
  call({ query: listFormatsQuery, variables });
