import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';
import dayjs from 'dayjs';
import * as R from 'ramda';

import history from '../../../routes/history';
import { importOffersFile, listImports } from '../../../requests/imports';
import { listPartners } from '../../../requests/partners';
import { listFields } from '../../../requests/fields';
import {
  listProfiles,
  createProfile,
  addfieldToProfile,
} from '../../../requests/profiles';
import { getCredentials } from '../../../requests/users';

import { getXlsxWorksheets, parseXlsxFile } from '../../../utils/fileParser';
import Filters from './filters';
import Table from './table';
import Profiles from './profiles';
import Imports from './imports';

const initialState = {
  credentials: undefined,
  suggestions: [],
  partner: undefined,
  file: undefined,
  worksheets: [],
  header: [],
  body: [],
  defaultFields: [],
  fields: [],
  profiles: [],
  imports: [],
  startingLine: 0,
  minDate: dayjs('01/01/1970').unix(),
  maxDate: dayjs().unix(),
  importDate: dayjs().format('YYYY-MM-DD'),
  page: 1,
  itemsOnPage: 10,
  hasHeader: false,
  isComplementary: true,
  rateType: 'OFFICIAL',
};

const reducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'setState':
      return { ...state, [payload.key]: payload.value };
    case 'resetState':
      return initialState;
    default:
      throw new Error();
  }
};

const Offers = ({ cookies }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    credentials,
    suggestions,
    partner,
    file,
    worksheets,
    header,
    body,
    defaultFields,
    fields,
    profiles,
    imports,
    startingLine,
    minDate,
    maxDate,
    importDate,
    page,
    itemsOnPage,
    hasHeader,
    isComplementary,
    rateType,
  } = state;

  const getImports = async () => {
    const { data } = await listImports({
      minDate,
      maxDate,
      page,
      itemsOnPage,
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'imports',
        value: data.paginatedImports,
      },
    });
  };

  const getProfilesAction = async () => {
    const { data, errors } = await listProfiles();

    if (errors) {
      cookies.remove('token');
      history.push('/signin');
    } else {
      dispatch({
        type: 'setState',
        payload: {
          key: 'profiles',
          value: data.allImportProfiles,
        },
      });
    }
  };

  const getFieldsAction = async () => {
    const { data } = await listFields();

    dispatch({
      type: 'setState',
      payload: {
        key: 'defaultFields',
        value: data.fields,
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'fields',
        value: data.fields,
      },
    });
  };

  const getCredentialsAction = async () => {
    const { data, errors } = await getCredentials();

    if (errors) {
      cookies.remove('token');
      history.push('/signin');
    } else {
      dispatch({
        type: 'setState',
        payload: {
          key: 'credentials',
          value: data.me,
        },
      });
    }
  };

  /**
   * Reset all states to the default values
   */
  const resetState = () => {
    dispatch({ type: 'resetState' });

    getProfilesAction();
    getFieldsAction();
    getImports();
  };

  useEffect(() => {
    getProfilesAction();
    getFieldsAction();
    getCredentialsAction();
  }, []);

  useEffect(() => {
    getImports();
  }, [page]);

  /**
   * Handle the file importation
   */
  const handleImportFile = async () => {
    const formattedFields = R.map(R.prop('code'))(fields);

    await importOffersFile({
      userUid: credentials.uid,
      partnerUid: partner.uid,
      fields: formattedFields,
      file,
      hasHeader,
      complementary: isComplementary,
      date: importDate,
      rateType,
    });

    resetState();
  };

  const handleHasHeaderChange = () => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'hasHeader',
        value: !hasHeader,
      },
    });
  };

  const handleIsComplementary = () => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'isComplementary',
        value: !isComplementary,
      },
    });
  };

  /**
   * Function handling when a file is chosen
   *
   * @param {*} e
   */
  const handleFileChange = async e => {
    const newFile = e.target.files[0];

    if (e.target.validity.valid) {
      dispatch({
        type: 'setState',
        payload: {
          key: 'file',
          value: newFile,
        },
      });

      const { worksheets: newWorksheets } = await getXlsxWorksheets(newFile);

      dispatch({
        type: 'setState',
        payload: {
          key: 'worksheets',
          value: newWorksheets,
        },
      });
    }
  };

  /**
   * Function exectued on the autocomplete input change
   *
   * @param {string} text
   */
  const handleOnAutocompleteChange = async text => {
    const { data } = await listPartners({ text });

    dispatch({
      type: 'setState',
      payload: {
        key: 'suggestions',
        value: data.searchPartners,
      },
    });
  };

  /**
   * Function exectued when an autocomplete item is clicked
   */
  const handleOnAutocompleteClick = suggestion => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'partner',
        value: suggestion,
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'suggestions',
        value: [],
      },
    });
  };

  /**
   * Function exectued when a worksheet is selected
   */
  const handleOnWorksheetClick = worksheet => async () => {
    const { header: newHeader, body: newBody } = await parseXlsxFile(
      file,
      worksheet,
      startingLine,
    );

    dispatch({
      type: 'setState',
      payload: {
        key: 'header',
        value: newHeader,
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'body',
        value: R.head(newBody),
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'fields',
        value: R.take(newHeader.length, fields),
      },
    });
  };

  /**
   * Function exectued when one of the select value change
   */
  const handleOnSelectChange = index => e => {
    const newFields = R.update(
      index,
      R.find(R.propEq('code', e.target.value), defaultFields),
      fields,
    );

    dispatch({
      type: 'setState',
      payload: {
        key: 'fields',
        value: newFields,
      },
    });
  };

  /**
   * Function that handles rate select value change
   *
   * @param {*} e
   */
  const handleOnRateSelectChange = e => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'rateType',
        value: e.target.value,
      },
    });
  };

  /**
   * Function to create a profile
   */
  const handleCreateProfile = async name => {
    const { data } = await createProfile({ name });

    fields.forEach(async (field, index) => {
      await addfieldToProfile({
        profileUid: data.ImportProfile.uid,
        fieldUid: field.uid,
        index,
      });
    });
  };

  /**
   * Function to select a profile and complete the header
   */
  const handleSelectProfile = profile => {
    let newFields = R.compose(
      R.map(R.prop('ImportField')),
      R.sort(R.ascend(R.prop('index'))),
    )(profile.fields);

    while (newFields.length < fields.length) {
      newFields = R.append(defaultFields[0], newFields);
    }

    dispatch({
      type: 'setState',
      payload: {
        key: 'fields',
        value: newFields,
      },
    });
  };

  /**
   * Function to handle the starting line input change
   */
  const handleStartingLineChange = e => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'startingLine',
        value: e.target.value,
      },
    });
  };

  /**
   * If no token in cookies, redirect to signin
   */
  if (!cookies.get('token')) {
    history.push('/signin');
    return '';
  }

  /**
   * Function to handle imports table previous
   */
  const handlePrevious = () => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'page',
        value: page - 1,
      },
    });
  };

  /**
   * Function to handle imports table next
   */
  const handleNext = () => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'page',
        value: page + 1,
      },
    });
  };

  /**
   * Handle import date change
   *
   * @param {*} e
   */
  const handleImportDateChange = e => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'importDate',
        value: e.target.value,
      },
    });
  };

  return (
    <div className="container">
      <div className="card">
        <div className="card-header">
          <div className="card-title h5">
            Choisissez un fichier excel ou csv
          </div>
        </div>
        <div className="card-body">
          <div>
            Ligne de départ :{' '}
            <input
              type="number"
              value={startingLine}
              onChange={handleStartingLineChange}
            />
          </div>
          <div>
            <div className="form-group">
              <label className="form-switch" htmlFor="header-checkbox">
                <input
                  id="header-checkbox"
                  type="checkbox"
                  onChange={handleHasHeaderChange}
                  checked={hasHeader ? 'checked' : ''}
                />
                <i className="form-icon" /> header
              </label>
            </div>
          </div>
          <div>
            <div className="form-group">
              <label className="form-switch" htmlFor="complementary-checkbox">
                <input
                  id="complementary-checkbox"
                  type="checkbox"
                  onChange={handleIsComplementary}
                  checked={isComplementary ? 'checked' : ''}
                />
                <i className="form-icon" /> complémentaire
              </label>
            </div>
          </div>
          <div className="mt-2 mb-2">
            <div>
              Date d&apos;import :{' '}
              <input
                type="date"
                id="start"
                name="trip-start"
                onChange={handleImportDateChange}
                value={importDate}
                max={dayjs().format('YYYY-MM-DD')}
              />
            </div>
          </div>
          <div className="mt-2 mb-2">
            <div>
              Type de tarifs :{' '}
              <select onChange={handleOnRateSelectChange} value={rateType}>
                <option value="OFFICIAL">Officiels</option>
                <option value="PRIVATE">Officieux</option>
                <option value="EXPORT">Exports</option>
              </select>
            </div>
          </div>
          <div className="mt-2 mb-2">
            <input type="file" required onChange={handleFileChange} />
          </div>
          {worksheets.length !== 0 && (
            <div className="mt-2">
              <div className="h6">Puis un onglet :</div>
              <ul>
                {worksheets.map(worksheet => (
                  <li>
                    <button
                      key={`worksheet-${worksheet}`}
                      type="button"
                      onClick={handleOnWorksheetClick(worksheet)}
                    >
                      {worksheet}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
        <button
          type="button"
          className="btn btn-primary mt-2"
          onClick={resetState}
          style={{
            width: '5rem',
            position: 'absolute',
            right: '1.2rem',
          }}
        >
          Recharger
        </button>
      </div>

      {header.length !== 0 && (
        <div>
          <div className="card mt-2">
            <div className="card-header">
              <div className="card-title h5">Sélectionnez votre partenaire</div>
            </div>
            <div className="card-body">
              <Filters
                credentials={credentials}
                partner={partner}
                handleOnAutocompleteChange={handleOnAutocompleteChange}
                handleOnAutocompleteClick={handleOnAutocompleteClick}
                formatValue={value => value.name}
                suggestions={suggestions}
              />
            </div>
          </div>

          {partner && (
            <div>
              <div className="card mt-2">
                <div className="card-header">
                  <div className="card-title h5">
                    Sélectionnez ou créez votre profile d&apos;import
                  </div>
                </div>
                <div className="card-body">
                  <Profiles
                    profiles={profiles}
                    handleCreateProfile={handleCreateProfile}
                    handleSelectProfile={handleSelectProfile}
                  />
                </div>
              </div>
              <div className="card mt-2">
                <div className="card-body" style={{ overflow: 'scroll' }}>
                  <Table
                    header={header}
                    body={body}
                    handleOnSelectChange={handleOnSelectChange}
                    defaultFields={defaultFields}
                    fields={fields}
                  />
                  {header.length !== 0 && (
                    <button
                      type="button"
                      className="btn btn-primary ml-2"
                      onClick={handleImportFile}
                    >
                      Importer
                    </button>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
      <div className="card mt-2">
        <div className="card-body">
          <Imports
            imports={imports}
            handlePrevious={handlePrevious}
            handleNext={handleNext}
            page={page}
            itemsOnPage={itemsOnPage}
          />
        </div>
      </div>
    </div>
  );
};

Offers.propTypes = {
  cookies: PropTypes.object.isRequired,
};

export default withCookies(Offers);
