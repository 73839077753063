import gql from 'graphql-tag';

import { call } from '../../utils/api';

const listProfilesQuery = gql`
  {
    allImportProfiles {
      uid
      name
      fields {
        ImportField {
          uid
          code
          name
          type
          required
        }
        index
      }
    }
  }
`;

const createProfileQuery = gql`
  mutation createImportProfile($name: String!) {
    ImportProfile: createImportProfile(name: $name) {
      uid
      name
    }
  }
`;

const addFieldToProfileQuery = gql`
  mutation addImportFieldToProfile(
    $profileUid: ID!
    $fieldUid: ID!
    $index: Int!
  ) {
    Result: addImportFieldToProfile(
      profileUid: $profileUid
      fieldUid: $fieldUid
      index: $index
    )
  }
`;

export const listProfiles = () => call({ query: listProfilesQuery });

export const createProfile = variables =>
  call({ query: createProfileQuery, variables });

export const addfieldToProfile = variables =>
  call({ query: addFieldToProfileQuery, variables });
