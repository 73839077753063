import React, { useState } from 'react';

import Offers from './offers';
import Ratings from './ratings';

// tab-item c-hand active

const OFFERS_TAB = 'offers';
const RATINGS_TAB = 'ratings';

const Main = () => {
  const [currentTab, setCurrentTab] = useState(OFFERS_TAB);

  const handleOnTabClick = key => () => {
    setCurrentTab(key);
  };

  /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */
  return (
    <div className="container">
      <ul className="tab tab-block">
        <li
          className={
            currentTab === OFFERS_TAB
              ? 'tab-item c-hand active'
              : 'tab-item c-hand'
          }
        >
          <a onClick={handleOnTabClick(OFFERS_TAB)}>Offres</a>
        </li>
        <li
          className={
            currentTab === RATINGS_TAB
              ? 'tab-item c-hand active'
              : 'tab-item c-hand'
          }
        >
          <a onClick={handleOnTabClick(RATINGS_TAB)}>Notes</a>
        </li>
      </ul>
      {currentTab === OFFERS_TAB && <Offers />}
      {currentTab === RATINGS_TAB && <Ratings />}
    </div>
  );
  /* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */
};

export default Main;
