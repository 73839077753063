import React, { useEffect, useReducer } from 'react';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';
import * as R from 'ramda';
import dayjs from 'dayjs';

import { getUnimportedCsv, importOffersFile } from '../../../requests/imports';
import { getXlsxWorksheets, parseXlsxFile } from '../../../utils/fileParser';
import { listWines } from '../../../requests/wines';
import { getCredentials } from '../../../requests/users';
import { listPartners } from '../../../requests/partners';
import { listFields } from '../../../requests/fields';
import { createLabelForWine } from '../../../requests/labels';
import history from '../../../routes/history';
import Filters from '../../main/offers/filters';
import EditImportTable from './edit-import-table';

const initialState = {
  header: [],
  body: [],
  wines: [],
  focusedAutocomplete: 0,
  savedWines: [],
  credentials: undefined,
  partner: undefined,
  partnerSuggestions: [],
  fields: [],
  defaultFields: [],
  loading: true,
  importDate: dayjs().format('YYYY-MM-DD'),
  rateType: 'OFFICIAL',
};

const reducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'setState':
      return { ...state, [payload.key]: payload.value };

    case 'resetState':
      return initialState;

    default:
      throw new Error();
  }
};

const EditImport = ({ match, cookies }) => {
  const {
    params: { uid },
  } = match;

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    header,
    body,
    wines,
    focusedAutocomplete,
    savedWines,
    credentials,
    partner,
    partnerSuggestions,
    fields,
    defaultFields,
    loading,
    importDate,
    rateType,
  } = state;

  /**
   * Get unimported csv from server thanks to the uid.
   * Parse it and put the header and the body in the state.
   */
  const getUnimportedCsvAction = async () => {
    const unimportedCsv = await getUnimportedCsv(uid);

    const csvFile = new Blob([unimportedCsv], { type: 'text/csv' });

    const { worksheets: newWorksheets } = await getXlsxWorksheets(csvFile);

    const { header: newHeader, body: newBody } = await parseXlsxFile(
      csvFile,
      newWorksheets[0],
      0,
    );

    dispatch({
      type: 'setState',
      payload: {
        key: 'header',
        value: newHeader,
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'body',
        value: newBody,
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'loading',
        value: false,
      },
    });
  };

  /**
   * Get wine name from saved wines (selected via autocomplete)
   *
   * @param {int} i
   */
  const getSavedWine = i => {
    const { values: { name } = { name: '' } } =
      R.find(R.propEq('i', i), savedWines) || {};

    return name;
  };

  /**
   * Fetch credentials
   */
  const getCredentialsAction = async () => {
    const { data, errors } = await getCredentials();

    if (errors) {
      cookies.remove('token');
      history.push('/signin');
    } else {
      dispatch({
        type: 'setState',
        payload: {
          key: 'credentials',
          value: data.me,
        },
      });
    }
  };

  /**
   * Fetch fields
   */
  const getFieldsAction = async () => {
    const { data } = await listFields();

    dispatch({
      type: 'setState',
      payload: {
        key: 'defaultFields',
        value: data.fields,
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'fields',
        value: data.fields,
      },
    });
  };

  /**
   * Filter wines on autocomplete change
   *
   * @param {int} i
   */
  const handleCreateLabelChange = i => async text => {
    const { data } = await listWines({ text });

    dispatch({
      type: 'setState',
      payload: {
        key: 'wines',
        value: R.take(10, data.filteredWines),
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'focusedAutocomplete',
        value: i,
      },
    });
  };

  /**
   * Create label on autocomplete item click
   *
   * @param {int} i
   * @param {array} items
   */
  const handleOnCreateLabelClick = async item => {
    const [errorType, ...fieldsWithoutErrorType] = fields;
    const wineIndex = R.findIndex(
      R.propEq('code', 'wine'),
      fieldsWithoutErrorType,
    );

    await createLabelForWine({
      wineUid: item.values.uid,
      labelName: item.items[wineIndex],
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'savedWines',
        value: [...savedWines, item],
      },
    });
  };

  /**
   * Create multiple labels
   *
   * @param {array} values
   */
  const handleOnCreateMultipleLabelsClick = async values => {
    values.forEach(async item => {
      const wineIndex = R.findIndex(R.propEq('code', 'wine'), fields);

      await createLabelForWine({
        wineUid: item.values.uid,
        labelName: item.items[wineIndex],
      });
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'savedWines',
        value: [...savedWines, ...values],
      },
    });
  };

  /**
   * Function exectued on the autocomplete input change
   */
  const handleOnPartnerChange = async text => {
    const { data } = await listPartners({ text });

    dispatch({
      type: 'setState',
      payload: {
        key: 'partnerSuggestions',
        value: data.searchPartners,
      },
    });
  };

  /**
   * Function exectued when an autocomplete item is clicked
   */
  const handleOnPartnerClick = suggestion => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'partner',
        value: suggestion,
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'suggestions',
        value: [],
      },
    });
  };

  /**
   * Function exectued when one of the select value change
   */
  const handleOnSelectChange = index => e => {
    const newFields = R.update(
      index,
      R.find(R.propEq('code', e.target.value), defaultFields),
      fields,
    );

    dispatch({
      type: 'setState',
      payload: {
        key: 'fields',
        value: newFields,
      },
    });
  };

  /**
   * Handle import date change
   *
   * @param {*} e
   */
  const handleImportDateChange = e => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'importDate',
        value: e.target.value,
      },
    });
  };

  /**
   * Function that handles rate select value change
   *
   * @param {*} e
   */
  const handleOnRateSelectChange = e => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'rateType',
        value: e.target.value,
      },
    });
  };

  const handleImport = async () => {
    // Format the fields, make them the same length as the header and remove the first value (error code)
    const formattedFields = R.compose(
      R.slice(1, Infinity),
      R.slice(0, header.length),
      R.map(R.prop('code')),
    )(fields);

    // Remove first item of body (error type) and transform it to string with line breaks
    const stringBody = body.map(item => item.slice(1).join(';')).join('\n');

    // Remove first item of the header and merge the rest with the body
    const csvContent = `${header.slice(1).join(';')}\n${stringBody}`;

    // Create csv file
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const file = new File([blob], 'import.csv');

    // Import
    await importOffersFile({
      userUid: credentials.uid,
      partnerUid: partner.uid,
      fields: formattedFields,
      file,
      hasHeader: false,
      date: importDate,
      rateType,
    });

    dispatch({ type: 'resetState' });

    history.push('/');
  };

  useEffect(() => {
    getCredentialsAction();
    getUnimportedCsvAction();
    getFieldsAction();
  }, []);

  if (loading) return <div className="loading loading-lg" />;

  // If body doesn't contain a WINE_LABEL error, it can't be edited
  if (
    R.compose(
      R.not,
      R.includes(true),
      R.map(R.includes('WINE_LABEL')),
    )([...header, ...body])
  ) {
    return (
      <div className="card mt-2">
        <div className="card-body">
          <p className="text-error text-center">
            Cet import n&apos;est pas modifiable, les erreurs ne sont pas liées
            aux labels des vins.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="card mt-2">
        <div className="card-header">
          <div className="card-title h5">Sélectionnez votre partenaire</div>
        </div>
        <div className="card-body">
          <Filters
            credentials={credentials}
            partner={partner}
            handleOnAutocompleteChange={handleOnPartnerChange}
            handleOnAutocompleteClick={handleOnPartnerClick}
            formatValue={value => value.name}
            suggestions={partnerSuggestions}
          />
          <div className="mt-2 mb-2">
            <div>
              Date d&apos;import :{' '}
              <input
                type="date"
                id="start"
                name="trip-start"
                onChange={handleImportDateChange}
                value={importDate}
                max={dayjs().format('YYYY-MM-DD')}
              />
            </div>
          </div>
          <div className="mt-2 mb-2">
            <div>
              Type de tarifs :{' '}
              <select onChange={handleOnRateSelectChange} value={rateType}>
                <option value="OFFICIAL">Officiels</option>
                <option value="PRIVATE">Officieux</option>
                <option value="EXPORT">Exports</option>
              </select>
            </div>
          </div>
        </div>
      </div>

      {partner && (
        <div>
          <div className="card mt-2">
            <div className="card-body" style={{ overflow: 'scroll' }}>
              <button
                type="button"
                className="btn btn-primary ml-2"
                onClick={handleImport}
              >
                Ré-importer
              </button>
              <EditImportTable
                header={header}
                body={body}
                getSavedWine={getSavedWine}
                onAutocompleteChange={handleCreateLabelChange}
                onValidate={handleOnCreateLabelClick}
                onValidateMultiple={handleOnCreateMultipleLabelsClick}
                wines={wines}
                focusedAutocomplete={focusedAutocomplete}
                handleOnSelectChange={handleOnSelectChange}
                fields={fields}
                defaultFields={defaultFields}
                dispatch={dispatch}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

EditImport.propTypes = {
  match: PropTypes.object.isRequired,
  cookies: PropTypes.object.isRequired,
};

export default withCookies(EditImport);
