import React from 'react';

export default () => (
  <svg
    version="1.1"
    id="Calque_1"
    viewBox="0 0 370.5 230.5"
    enableBackground="new 0 0 370.5 230.5"
    xmlSpace="preserve"
  >
    <g>
      <path
        fill="#A8253E"
        d="M182.8503,79.1822h3.2393l0.3721-28.6231c0,0,20.7871-23.5771,19.8808-47.3125
		c0,0-0.8047,0.0947-1.4551,0.124c-0.3105,5.958-3.3554,28.4473-20.0068,43.3409c0,0-17.0234-16.1153-19.1328-43.087
		c-0.8272-0.1494-1.582-0.3779-1.582-0.3779s-1.8838,22.2959,18.7587,47.3125C183.0037,61.3266,182.8503,79.1822,182.8503,79.1822z"
      />
      <rect
        x="170.28"
        y="82.2768"
        fill="#A8253E"
        width="28.7617"
        height="1.9765"
      />
    </g>
    <g>
      <path
        fill="#101010"
        d="M123.6677,115.4126c0-5.3808,0-6.3418-0.0664-7.4463c-0.0625-1.1718-0.3408-1.7256-1.4844-1.9638
		c-0.2705-0.0743-0.8593-0.1094-1.164-0.1094c-0.1416,0-0.2784-0.0645-0.2784-0.2031c0-0.209,0.1641-0.2784,0.5489-0.2784
		c1.5508,0,3.7304,0.1094,3.8867,0.1094c0.8389,0,2.1797-0.1094,3.7656-0.1094c5.6543,0,6.8936,3.4532,6.8936,5.1729
		c0,2.8252-1.6182,4.5186-3.2442,6.1992c2.5586,0.7969,5.9668,3.2412,5.9668,7.2754c0,3.6885-2.8945,7.5107-8.792,7.5107
		c-0.3769,0-1.4795-0.0595-2.4775-0.0986c-1.0029-0.0703-1.9707-0.1094-2.1123-0.1094c-0.0596,0-0.6504,0-1.3672,0.0391
		c-0.6934,0-1.4844,0.0703-1.999,0.0703c-0.3799,0-0.5479-0.0703-0.5479-0.2783c0-0.1045,0.0616-0.2041,0.2705-0.2041
		c0.3047,0,0.7198-0.0693,1.0372-0.1387c0.6875-0.1386,0.8544-0.8984,0.9951-1.8984c0.1689-1.4424,0.1689-4.1729,0.1689-7.4746
		V115.4126z M126.4304,116.1656c0,0.2451,0.0674,0.3125,0.2363,0.3798c0.211,0.0371,0.834,0.1407,2.1075,0.1407
		c1.9287,0,2.4101-0.0743,3.1279-0.8985c0.7246-0.8183,1.2461-2.0644,1.2461-3.4795c0-2.8603-1.2735-5.9277-4.6524-5.9277
		c-0.3798,0-1.1045,0.0342-1.6533,0.1709c-0.3125,0.0674-0.4121,0.1709-0.4121,0.3848V116.1656z M126.4304,119.8238
		c0,2.6464,0,6.9209,0.0352,7.4423c0.1015,1.6856,0.1015,2.167,1.164,2.6856c0.9336,0.4473,2.418,0.5215,3.0332,0.5215
		c1.8623,0,4.8682-1,4.8682-4.8955c0-2.0655-0.6904-5.0713-3.3467-6.8955c-1.1758-0.7891-2.0351-0.8985-2.8613-0.9981
		c-0.4473-0.0693-2.1748-0.0693-2.6885-0.0693c-0.1367,0-0.2041,0.0693-0.2041,0.2431V119.8238z"
      />
      <path
        fill="#101010"
        d="M144.1521,117.7837c0-4.543,0-5.3711-0.0772-6.2949c-0.0644-0.9365-0.2412-1.4844-1.2363-1.6338
		c-0.2383-0.0195-0.7598-0.0615-1.0322-0.0615c-0.1074,0-0.2412-0.1367-0.2412-0.2383c0-0.1738,0.1338-0.2461,0.4736-0.2461
		c1.3809,0,3.21,0.0723,3.4883,0.0723c0.2754,0,2.1719-0.0723,3.0703-0.0723c0.335,0,0.5166,0.0723,0.5166,0.2461
		c0,0.1016-0.1416,0.2383-0.2832,0.2383c-0.2031,0-0.377,0-0.752,0.0615c-0.7998,0.1494-0.9687,0.6631-1.038,1.6338
		c-0.0664,0.9238-0.0664,1.7519-0.0664,6.2949v4.1055c0,4.2373,0.9277,5.9971,2.2412,7.1689c1.4765,1.3409,2.79,1.586,4.5097,1.586
		c1.8623,0,3.6573-0.8262,4.6866-2.0723c1.415-1.6855,1.7285-4.0957,1.7285-7.0947v-3.6934c0-4.543-0.0401-5.3711-0.0772-6.2949
		c-0.0322-0.9365-0.2012-1.4844-1.1963-1.6338c-0.2461-0.0195-0.7597-0.0615-1.0381-0.0615c-0.1416,0-0.2431-0.1367-0.2431-0.2383
		c0-0.1738,0.1416-0.2461,0.4892-0.2461c1.3379,0,3.0928,0.0723,3.2686,0.0723c0.2109,0,1.6533-0.0723,2.5518-0.0723
		c0.3476,0,0.4814,0.0723,0.4814,0.2461c0,0.1016-0.1016,0.2383-0.2432,0.2383c-0.2031,0-0.3798,0-0.7588,0.0615
		c-0.8242,0.1768-0.996,0.6631-1.0625,1.6338c-0.0703,0.9238-0.04,1.7519-0.04,6.2949v3.1377c0,3.1748-0.415,6.7217-2.8203,8.7901
		c-2.2109,1.8984-4.5547,2.1367-6.3496,2.1367c-0.9951,0-4.1699-0.0674-6.3018-2.0703c-1.4843-1.375-2.6484-3.4053-2.6484-7.6797
		V117.7837z"
      />
      <path
        fill="#101010"
        d="M170.0642,117.7837c0-4.543,0-5.3711-0.0674-6.2949c-0.0664-1.0029-0.2431-1.4844-1.2383-1.6338
		c-0.2431-0.0195-0.7597-0.0615-1.0351-0.0615c-0.0967,0-0.2383-0.1367-0.2383-0.2383c0-0.1738,0.1416-0.2461,0.4814-0.2461
		c1.3799,0,3.4102,0.0723,3.587,0.0723c0.3056,0,2.8574-0.0723,3.79-0.0723c1.9268,0,4.0586,0.1738,5.6445,1.2754
		c0.7598,0.5566,2.1055,1.9668,2.1055,3.9971c0,2.1748-0.8936,4.2724-3.7236,6.6894c2.4824,3.0957,4.5849,5.752,6.3447,7.5791
		c1.624,1.6524,2.6142,1.9278,3.3779,2.0303c0.584,0.1084,0.8945,0.1084,1.0703,0.1084c0.1641,0,0.2696,0.1367,0.2696,0.2441
		c0,0.168-0.168,0.2383-0.7207,0.2383h-1.8926c-1.7276,0-2.4844-0.1738-3.2412-0.5908c-1.3106-0.6807-2.4131-2.1006-4.1407-4.2998
		c-1.2734-1.5859-2.6113-3.4873-3.2744-4.2744c-0.1357-0.1416-0.206-0.1739-0.4091-0.1739l-3.8594-0.0742
		c-0.1416,0-0.209,0.0742-0.209,0.2481v0.6855c0,2.8291,0,5.1055,0.1367,6.3047c0.1016,0.8584,0.2774,1.4151,1.2041,1.5537
		c0.419,0.0694,1.0723,0.1387,1.3477,0.1387c0.208,0,0.2451,0.1367,0.2451,0.2441c0,0.1289-0.1406,0.2383-0.4883,0.2383
		c-1.6582,0-3.5771-0.1094-3.7187-0.1094c-0.0352,0-2.0655,0.1094-3.001,0.1094c-0.3028,0-0.4795-0.0703-0.4795-0.2383
		c0-0.1074,0.0674-0.2441,0.2431-0.2441c0.2735,0,0.6534-0.0693,0.9288-0.1387c0.5888-0.1386,0.6504-0.6953,0.792-1.5537
		c0.1689-1.1992,0.1689-3.5146,0.1689-6.3398V117.7837z M172.6853,120.2681c0,0.1767,0.0674,0.2754,0.209,0.3799
		c0.4492,0.2383,1.998,0.4472,3.3457,0.4472c0.7168,0,1.5459-0.04,2.2734-0.5166c1.0303-0.6826,1.8242-2.2392,1.8242-4.4726
		c0-3.6563-1.998-5.7647-5.2392-5.7647c-0.8936,0-1.8574,0.1045-2.1748,0.1768c-0.1338,0.0664-0.2383,0.1689-0.2383,0.3447V120.2681
		z"
      />
      <path
        fill="#101010"
        d="M192.3699,117.7837c0-4.543,0-5.3711-0.0684-6.2949c-0.0684-1.0029-0.2422-1.4844-1.2461-1.6338
		c-0.2383-0.0195-0.7519-0.0615-1.0234-0.0615c-0.1075,0-0.25-0.1367-0.25-0.2383c0-0.1738,0.1425-0.2461,0.4824-0.2461
		c1.3809,0,3.3516,0.0723,3.5195,0.0723c0.2031,0,6.5528,0.0322,7.2344,0c0.5879-0.0371,1.1367-0.1367,1.3789-0.1768
		c0.1367-0.0342,0.2793-0.1338,0.4141-0.1338c0.1074,0,0.1347,0.1338,0.1347,0.2735c0,0.2109-0.1679,0.5507-0.2382,1.3798
		c-0.0313,0.2676-0.1016,1.584-0.1739,1.9268c-0.0312,0.1387-0.1367,0.3125-0.2695,0.3125c-0.1797,0-0.2441-0.1367-0.2441-0.3799
		c0-0.2012-0.0352-0.6826-0.1719-1.0351c-0.2071-0.4815-0.5215-0.6856-2.1739-0.8936c-0.5195-0.0703-4.0332-0.1025-4.3808-0.1025
		c-0.1348,0-0.1699,0.1025-0.1699,0.3105v7.958c0,0.209,0,0.3106,0.1699,0.3106c0.3867,0,4.3808,0,5.0742-0.0664
		c0.7188-0.0752,1.1719-0.1026,1.4805-0.4121c0.2051-0.211,0.3398-0.3829,0.4472-0.3829c0.1016,0,0.17,0.0694,0.17,0.2383
		c0,0.1739-0.17,0.6553-0.2442,1.6192c-0.0664,0.5898-0.1367,1.6923-0.1367,1.8965c0,0.2421-0.0644,0.5556-0.2695,0.5556
		c-0.1426,0-0.209-0.1094-0.209-0.2734c0-0.2822,0-0.586-0.1367-0.9727c-0.1075-0.4121-0.3828-0.7549-1.5508-0.8887
		c-0.832-0.1093-4.0664-0.1396-4.5879-0.1396c-0.1719,0-0.207,0.0996-0.207,0.2012v2.4502c0,1.0029-0.0313,4.4091,0,4.999
		c0.0703,1.9717,0.6191,2.3437,3.6172,2.3437c0.7929,0,2.25,0,3.0332-0.3125c0.7929-0.3408,1.207-0.8593,1.3789-2.0312
		c0.0761-0.3467,0.1406-0.4463,0.3144-0.4463c0.168,0,0.2031,0.2383,0.2031,0.4463c0,0.2041-0.2421,2.1992-0.4121,2.8223
		c-0.2031,0.7597-0.5215,0.7597-1.6875,0.7597c-2.2734,0-4.0293-0.0664-5.2754-0.1015c-1.2734-0.0743-2.0644-0.0743-2.5117-0.0743
		c-0.0742,0-0.6621,0-1.3125,0.0391c-0.5879,0.0352-1.2695,0.0703-1.7597,0.0703c-0.3067,0-0.4825-0.0703-0.4825-0.2383
		c0-0.1074,0.0743-0.2441,0.2461-0.2441c0.2754,0,0.6563-0.1084,0.9336-0.1387c0.5801-0.0996,0.6504-0.6953,0.791-1.5537
		c0.17-1.1992,0.17-3.5146,0.17-6.3398V117.7837z"
      />
      <path
        fill="#101010"
        d="M215.114,109.895c0.3809-0.9677,0.5215-1.1367,0.7246-1.1367c0.3106,0,0.416,0.4121,0.7305,1.0772
		c0.5508,1.2314,5.4062,13.3252,7.2676,17.7734c1.1054,2.6162,1.9296,3.001,2.5839,3.207c0.4493,0.1387,0.8965,0.1729,1.2012,0.1729
		c0.1699,0,0.3848,0.0703,0.3848,0.2441c0,0.168-0.3438,0.2383-0.6895,0.2383c-0.4472,0-2.6875,0-4.7929-0.0703
		c-0.584-0.0391-1.0625-0.0391-1.0625-0.208c0-0.1338,0.0644-0.1338,0.205-0.2041c0.1719-0.0693,0.4825-0.3125,0.2735-0.7891
		l-2.7227-6.7265c-0.0664-0.1367-0.1015-0.169-0.2754-0.169h-6.9902c-0.1406,0-0.2461,0.0694-0.3184,0.2383l-1.7187,4.6895
		c-0.2793,0.7197-0.4531,1.4521-0.4531,1.9677c0,0.5782,0.5195,0.7891,1.041,0.7891h0.2773c0.2383,0,0.3047,0.0996,0.3047,0.2441
		c0,0.168-0.1699,0.2383-0.4453,0.2383c-0.7246,0-2.3789-0.1094-2.7246-0.1094c-0.3125,0-1.8262,0.1094-3.0606,0.1094
		c-0.3847,0-0.5898-0.0703-0.5898-0.2383c0-0.1445,0.1367-0.2441,0.2734-0.2441c0.209,0,0.6524-0.0342,0.8985-0.0693
		c1.375-0.169,1.9609-1.208,2.5468-2.6875L215.114,109.895z M218.4968,122.0982c0.1367,0,0.1367-0.0742,0.0977-0.209l-2.9903-8.0322
		c-0.1777-0.4473-0.3476-0.4473-0.5214,0l-2.8262,8.0322c-0.0332,0.1348,0,0.209,0.1074,0.209H218.4968z"
      />
      <path
        fill="#101010"
        d="M229.5984,117.7837c0-4.543,0-5.3711-0.0723-6.2949c-0.0683-0.9365-0.2383-1.4844-1.2363-1.6338
		c-0.2481-0.0195-0.7637-0.0615-1.0352-0.0615c-0.1015,0-0.2422-0.1367-0.2422-0.2383c0-0.1738,0.1407-0.2461,0.4805-0.2461
		c1.3809,0,3.2031,0.0723,3.4863,0.0723c0.2715,0,2.168-0.0723,3.0664-0.0723c0.3418,0,0.5176,0.0723,0.5176,0.2461
		c0,0.1016-0.1426,0.2383-0.2793,0.2383c-0.2109,0-0.3789,0-0.7578,0.0615c-0.793,0.1494-0.9648,0.6631-1.0351,1.6338
		c-0.0704,0.9238-0.0704,1.7519-0.0704,6.2949v4.1055c0,4.2373,0.9278,5.9971,2.2442,7.1689c1.4824,1.3409,2.793,1.586,4.5156,1.586
		c1.8555,0,3.6484-0.8262,4.6856-2.0723c1.4101-1.6855,1.7207-4.0957,1.7207-7.0947v-3.6934c0-4.543-0.0352-5.3711-0.0704-6.2949
		c-0.0312-0.9365-0.207-1.4844-1.2031-1.6338c-0.2383-0.0195-0.7597-0.0615-1.0351-0.0615c-0.1368,0-0.2383-0.1367-0.2383-0.2383
		c0-0.1738,0.1289-0.2461,0.4746-0.2461c1.3476,0,3.1074,0.0723,3.2754,0.0723c0.2109,0,1.6543-0.0723,2.5605-0.0723
		c0.3399,0,0.4727,0.0723,0.4727,0.2461c0,0.1016-0.1016,0.2383-0.2344,0.2383c-0.209,0-0.3887,0-0.7598,0.0615
		c-0.832,0.1768-1,0.6631-1.0761,1.6338c-0.0606,0.9238-0.0274,1.7519-0.0274,6.2949v3.1377c0,3.1748-0.414,6.7217-2.8242,8.7901
		c-2.2129,1.8984-4.5488,2.1367-6.3437,2.1367c-1.0039,0-4.17-0.0674-6.3106-2.0703c-1.4765-1.375-2.6484-3.4053-2.6484-7.6797
		V117.7837z"
      />
    </g>
    <g>
      <path
        fill="#101010"
        d="M10.946,170.9682c0,5.1338,0.1416,7.373,0.7715,7.998c0.5586,0.5567,1.4649,0.8096,4.1934,0.8096
		c1.8515,0,3.3828-0.0352,4.2197-1.0527c0.4541-0.5557,0.8037-1.4346,0.9131-2.0899c0.0322-0.2832,0.1016-0.457,0.3105-0.457
		c0.1758,0,0.211,0.1348,0.211,0.5166c0,0.3916-0.2461,2.4873-0.5215,3.5c-0.251,0.7988-0.3545,0.9385-2.2022,0.9385
		c-2.5185,0-4.3339-0.0674-5.8007-0.0996c-1.4639-0.0743-2.5469-0.1016-3.5664-0.1016c-0.1348,0-0.7354,0.0273-1.4249,0.0273
		c-0.6982,0.0342-1.4648,0.0743-1.999,0.0743c-0.3799,0-0.5527-0.0743-0.5527-0.2784c0-0.1064,0.0664-0.2158,0.2822-0.2158
		c0.3106,0,0.7305-0.0664,1.043-0.1416c0.7002-0.1357,0.876-0.9033,1.0176-1.9189c0.1689-1.4639,0.1689-4.2217,0.1689-7.5781
		v-6.1456c0-5.4511,0-6.4296-0.0674-7.5439c-0.0693-1.1895-0.3525-1.748-1.5068-1.9932c-0.2754-0.0742-0.8711-0.1015-1.1816-0.1015
		c-0.1407,0-0.2774-0.0723-0.2774-0.2139c0-0.2109,0.169-0.2803,0.5537-0.2803c1.5762,0,3.7754,0.1045,3.9444,0.1045
		c0.1787,0,2.7275-0.1045,3.7753-0.1045c0.3848,0,0.5606,0.0694,0.5606,0.2803c0,0.1416-0.1367,0.2139-0.2774,0.2139
		c-0.249,0-0.7353,0.0273-1.1542,0.1015c-1.0206,0.169-1.2911,0.7647-1.3633,1.9932c-0.0694,1.1143-0.0694,2.0928-0.0694,7.5439
		V170.9682z"
      />
      <path
        fill="#101010"
        d="M32.0798,159.1685c0.3897-0.9863,0.5264-1.1602,0.7344-1.1602c0.3184,0,0.4199,0.418,0.7373,1.085
		c0.5537,1.2558,5.4834,13.5215,7.373,18.0215c1.1094,2.6611,1.9532,3.0429,2.6163,3.2539
		c0.4511,0.1367,0.9062,0.1689,1.2207,0.1689c0.1767,0,0.3847,0.0723,0.3847,0.251c0,0.1689-0.3447,0.2432-0.6992,0.2432
		c-0.4551,0-2.7285,0-4.8555-0.0743c-0.5957-0.0273-1.0878-0.0273-1.0878-0.2041c0-0.1435,0.0771-0.1435,0.2138-0.2158
		c0.1758-0.0664,0.4942-0.3154,0.2783-0.7969l-2.7558-6.8164c-0.0694-0.1386-0.1094-0.1738-0.2832-0.1738H28.865
		c-0.1358,0-0.2451,0.0674-0.3096,0.2442l-1.7451,4.7558c-0.2832,0.7266-0.459,1.4639-0.459,1.9902
		c0,0.586,0.5254,0.7969,1.0469,0.7969h0.2832c0.2383,0,0.3174,0.1094,0.3174,0.251c0,0.1689-0.1758,0.2432-0.459,0.2432
		c-0.7295,0-2.4102-0.1016-2.7549-0.1016c-0.3184,0-1.8574,0.1016-3.1133,0.1016c-0.3799,0-0.5957-0.0743-0.5957-0.2432
		c0-0.1416,0.1494-0.251,0.2832-0.251c0.211,0,0.6631-0.0322,0.9063-0.0664c1.3974-0.1719,1.998-1.2217,2.5859-2.7207
		L32.0798,159.1685z M35.5076,171.5269c0.1367,0,0.1367-0.0664,0.1025-0.211l-3.0361-8.1367c-0.1787-0.4521-0.3526-0.4521-0.5215,0
		l-2.8721,8.1367c-0.0322,0.1446,0,0.211,0.1016,0.211H35.5076z"
      />
      <path
        fill="#101010"
        d="M46.7546,167.1586c0-4.6045,0-5.4492-0.0693-6.3867c-0.0713-0.9434-0.2432-1.5098-1.2559-1.6436
		c-0.2431-0.0351-0.7695-0.0693-1.0478-0.0693c-0.1065,0-0.2481-0.1396-0.2481-0.2432c0-0.1738,0.1416-0.249,0.4942-0.249
		c1.3955,0,3.247,0.0752,3.5254,0.0752c0.2822,0,2.2011-0.0752,3.1045-0.0752c0.3525,0,0.5341,0.0752,0.5341,0.249
		c0,0.1036-0.1416,0.2432-0.2832,0.2432c-0.208,0-0.3798,0-0.7695,0.0693c-0.8037,0.1338-0.9805,0.6631-1.0449,1.6436
		c-0.0694,0.9375-0.0694,1.7822-0.0694,6.3867v4.1572c0,4.2998,0.9385,6.0821,2.2637,7.2686
		c1.5117,1.3652,2.835,1.6084,4.5772,1.6084c1.8916,0,3.7011-0.8389,4.7539-2.1006c1.4345-1.707,1.7451-4.1523,1.7451-7.1933
		v-3.7403c0-4.6045-0.0352-5.4492-0.0674-6.3867c-0.0371-0.9434-0.2109-1.5098-1.2285-1.6436
		c-0.2383-0.0351-0.7647-0.0693-1.043-0.0693c-0.1416,0-0.2431-0.1396-0.2431-0.2432c0-0.1738,0.1367-0.249,0.4814-0.249
		c1.3652,0,3.1475,0.0752,3.3213,0.0752c0.2109,0,1.6758-0.0752,2.5869-0.0752c0.3447,0,0.4941,0.0752,0.4941,0.249
		c0,0.1036-0.1093,0.2432-0.2509,0.2432c-0.209,0-0.3848,0-0.7647,0.0693c-0.8369,0.1739-1.0176,0.6631-1.0849,1.6436
		c-0.0694,0.9375-0.042,1.7822-0.042,6.3867v3.1797c0,3.2168-0.417,6.8164-2.8623,8.9111
		c-2.2315,1.9209-4.6065,2.1592-6.4239,2.1592c-1.0127,0-4.2275-0.0615-6.3896-2.0869c-1.5039-1.3975-2.6934-3.458-2.6934-7.7949
		V167.1586z"
      />
      <path
        fill="#101010"
        d="M73.032,167.1586c0-4.6045,0-5.4492-0.0742-6.3867c-0.0674-1.0156-0.2432-1.5098-1.2481-1.6436
		c-0.251-0.0351-0.7773-0.0693-1.0557-0.0693c-0.1064,0-0.2431-0.1396-0.2431-0.2432c0-0.1738,0.1367-0.249,0.4863-0.249
		c1.3975,0,3.4658,0.0752,3.6348,0.0752c0.3174,0,2.8965-0.0752,3.8418-0.0752c1.959,0,4.1211,0.1787,5.7295,1.2959
		c0.7646,0.5615,2.1269,1.9961,2.1269,4.0459c0,2.2022-0.9053,4.3369-3.7676,6.7822c2.5166,3.1475,4.6446,5.8331,6.4268,7.6797
		c1.6426,1.6826,2.6553,1.9561,3.4199,2.0655c0.5938,0.1015,0.9111,0.1015,1.085,0.1015c0.1767,0,0.2783,0.1416,0.2783,0.251
		c0,0.1689-0.1738,0.2432-0.7305,0.2432h-1.9258c-1.747,0-2.5166-0.1787-3.2812-0.5957c-1.3311-0.6954-2.4434-2.1348-4.1924-4.3643
		c-1.291-1.6055-2.6562-3.5293-3.3164-4.334c-0.1416-0.1387-0.2041-0.1728-0.4199-0.1728l-3.9092-0.0703
		c-0.1416,0-0.2109,0.0703-0.2109,0.2431v0.7031c0,2.8623,0,5.1651,0.1357,6.3868c0.1074,0.874,0.2783,1.4326,1.2246,1.5683
		c0.417,0.0752,1.0869,0.1416,1.3701,0.1416c0.2002,0,0.2403,0.1416,0.2403,0.251c0,0.1416-0.1416,0.2432-0.4912,0.2432
		c-1.6758,0-3.6338-0.1016-3.7754-0.1016c-0.0274,0-2.0957,0.1016-3.041,0.1016c-0.3106,0-0.4844-0.0743-0.4844-0.2432
		c0-0.1094,0.0674-0.251,0.249-0.251c0.2773,0,0.6602-0.0664,0.9375-0.1416c0.5908-0.1357,0.6631-0.6943,0.7998-1.5683
		c0.1807-1.2217,0.1807-3.5577,0.1807-6.4287V167.1586z M75.6863,169.6734c0,0.1787,0.0693,0.2822,0.2109,0.3916
		c0.4512,0.2412,2.0254,0.4521,3.3828,0.4521c0.7422,0,1.5742-0.0351,2.3086-0.5215c1.0479-0.6953,1.8516-2.2763,1.8516-4.5449
		c0-3.7012-2.0254-5.831-5.3067-5.831c-0.914,0-1.8867,0.1044-2.2041,0.1718c-0.1416,0.0713-0.2431,0.1807-0.2431,0.3496V169.6734z"
      />
      <path
        fill="#101010"
        d="M95.6355,167.1586c0-4.6045,0-5.4492-0.0713-6.3867c-0.0703-1.0156-0.2461-1.5098-1.2568-1.6436
		c-0.2432-0.0351-0.7637-0.0693-1.0469-0.0693c-0.1094,0-0.2383-0.1396-0.2383-0.2432c0-0.1738,0.1289-0.249,0.4815-0.249
		c1.3974,0,3.3906,0.0752,3.5595,0.0752c0.208,0,6.6377,0.0342,7.3399,0c0.5937-0.04,1.1543-0.1445,1.3925-0.1719
		c0.1465-0.0439,0.2832-0.1464,0.4248-0.1464c0.1016,0,0.1338,0.1464,0.1338,0.2783c0,0.2138-0.1689,0.5664-0.2353,1.4052
		c-0.0401,0.2706-0.1094,1.5987-0.1768,1.9502c-0.04,0.1416-0.1465,0.3184-0.2832,0.3184c-0.1738,0-0.248-0.1445-0.248-0.3877
		c0-0.208-0.0274-0.6992-0.169-1.0449c-0.2109-0.4942-0.5283-0.7022-2.2041-0.9111c-0.5185-0.0694-4.0859-0.1036-4.4355-0.1036
		c-0.1367,0-0.1768,0.1036-0.1768,0.3125v8.0723c0,0.209,0,0.3154,0.1768,0.3154c0.3847,0,4.4355,0,5.1406-0.0722
		c0.7275-0.0694,1.1865-0.1045,1.499-0.4141c0.2061-0.2158,0.3428-0.3896,0.4571-0.3896c0.0966,0,0.1689,0.0664,0.1689,0.2431
		c0,0.1729-0.1689,0.6602-0.2412,1.6377c-0.0693,0.5957-0.1416,1.7178-0.1416,1.9268c0,0.2431-0.0742,0.5556-0.2773,0.5556
		c-0.1416,0-0.211-0.1045-0.211-0.2783c0-0.2773,0-0.5908-0.1416-0.9775c-0.1015-0.4219-0.3848-0.7647-1.5664-0.9082
		c-0.8437-0.1074-4.126-0.1348-4.6523-0.1348c-0.1709,0-0.211,0.0996-0.211,0.2041v2.4776c0,1.0195-0.0322,4.4726,0,5.0683
		c0.0752,1.9902,0.6358,2.375,3.669,2.375c0.8066,0,2.2744,0,3.0781-0.3193c0.8047-0.3428,1.2168-0.8643,1.3926-2.0557
		c0.0722-0.3525,0.1494-0.4541,0.3222-0.4541c0.169,0,0.2041,0.2451,0.2041,0.4541c0,0.2129-0.2431,2.2344-0.4199,2.8594
		c-0.2031,0.7744-0.5234,0.7744-1.707,0.7744c-2.3037,0-4.0938-0.0693-5.3447-0.1094c-1.2911-0.0615-2.0996-0.0615-2.5567-0.0615
		c-0.0625,0-0.6582,0-1.3252,0.0273c-0.5937,0.0342-1.289,0.0743-1.7773,0.0743c-0.3154,0-0.4922-0.0743-0.4922-0.2432
		c0-0.1094,0.0752-0.251,0.2432-0.251c0.2832,0,0.6679-0.1015,0.9462-0.1416c0.5958-0.0967,0.6602-0.6943,0.8018-1.5683
		c0.1758-1.2217,0.1758-3.5577,0.1758-6.4287V167.1586z"
      />
      <path
        fill="#101010"
        d="M115.3025,176.976c0.0693,2.4424,0.3525,3.1816,1.1894,3.3926c0.5586,0.1367,1.2608,0.1689,1.541,0.1689
		c0.1348,0,0.2413,0.1094,0.2413,0.251c0,0.1689-0.2139,0.2432-0.5616,0.2432c-1.747,0-2.9707-0.1016-3.2461-0.1016
		c-0.2783,0-1.5742,0.1016-2.9716,0.1016c-0.3106,0-0.4864-0.0401-0.4864-0.2432c0-0.1416,0.0996-0.251,0.2412-0.251
		c0.2452,0,0.8038-0.0322,1.2657-0.1689c0.7617-0.211,0.8681-1.0147,0.8681-3.7061v-17.0127c0-1.1797,0.1768-1.5,0.42-1.5
		c0.3447,0,0.871,0.5957,1.1894,0.9092c0.4863,0.4863,4.957,5.2373,9.7051,10.1328c3.041,3.1446,6.3271,6.6719,7.2656,7.6446
		l-0.3105-14.879c-0.0342-1.9228-0.2159-2.5859-1.1543-2.7597c-0.5606-0.1045-1.2559-0.1387-1.5059-0.1387
		c-0.2041,0-0.2363-0.1738-0.2363-0.2832c0-0.1738,0.2363-0.209,0.5888-0.209c1.3975,0,2.8692,0.0752,3.2188,0.0752
		c0.3457,0,1.3603-0.0752,2.6162-0.0752c0.3106,0,0.5537,0.0352,0.5537,0.209c0,0.1094-0.1367,0.2832-0.3447,0.2832
		c-0.1367,0-0.3848,0-0.7373,0.0693c-1.0108,0.2081-1.0801,0.8038-1.0801,2.5811l-0.0664,17.4014
		c0,1.9531-0.043,2.1298-0.2432,2.1298c-0.3183,0-0.6357-0.249-2.3466-1.8222c-0.3145-0.2735-4.753-4.6416-8-8.0332
		c-3.5596-3.7324-7.0245-7.3672-7.9629-8.4141L115.3025,176.976z"
      />
      <path
        fill="#101010"
        d="M148.9499,172.399c0,2.871,0,5.207,0.1338,6.4287c0.1094,0.874,0.251,1.4326,1.1963,1.5683
		c0.4121,0.0752,1.1153,0.1416,1.3858,0.1416c0.2158,0,0.2558,0.1416,0.2558,0.251c0,0.1416-0.1416,0.2432-0.4941,0.2432
		c-1.6738,0-3.6319-0.1016-3.8028-0.1016c-0.1464,0-2.2763,0.1016-3.1855,0.1016c-0.3525,0-0.4863-0.0743-0.4863-0.2432
		c0-0.1094,0.0722-0.2188,0.248-0.2188c0.2783,0,0.6231-0.0644,0.9063-0.1337c0.6279-0.1368,0.8017-0.7344,0.9111-1.6084
		c0.1338-1.2217,0.1338-3.5577,0.1338-6.4287v-12.4668l-4.4307,0.0751c-1.9238,0.0274-2.6582,0.2432-3.1172,0.9385
		c-0.3457,0.5205-0.3798,0.7344-0.4795,0.9424c-0.0771,0.2432-0.1835,0.2783-0.3203,0.2783c-0.0674,0-0.208-0.0674-0.208-0.2431
		c0-0.3174,0.628-2.9717,0.6621-3.2149c0.0674-0.1758,0.209-0.5586,0.3184-0.5586c0.2031,0,0.5185,0.2491,1.3574,0.3477
		c0.8789,0.1045,2.0654,0.1445,2.4102,0.1445h11.6025c1.0078,0,1.71-0.0752,2.1963-0.1445c0.459-0.0713,0.7295-0.1338,0.8711-0.1338
		c0.1416,0,0.1416,0.1689,0.1416,0.3447c0,0.9112-0.1094,3.041-0.1094,3.3906c0,0.2432-0.1338,0.3799-0.2705,0.3799
		c-0.1465,0-0.2129-0.1035-0.25-0.5215l-0.0322-0.3173c-0.1026-0.9063-0.7695-1.5733-3.7061-1.6329l-3.8379-0.0751V172.399z"
      />
      <path
        fill="#101010"
        d="M189.1257,180.3266c-1.2617,0.665-3.6318,1.1914-5.4883,1.1914c-9.6709,0-13.6504-7.4443-13.6504-13.7266
		c0-5.6601,4.1202-13.6552,13.7569-13.6552c7.8642,0,13.7295,4.9921,13.7295,13.0888c0,6.1543-3.8828,10.2422-6.1856,11.7412
		l7.166,3.7061c7.6446,3.9443,12.9551,4.1182,14.6973,4.1182c1.75,0,4.4785-0.3819,6.8887-1.3604
		c0.2031-0.0693,0.3789-0.1387,0.4902-0.1387c0.1367,0,0.2051,0.1016,0.2051,0.211c0,0.1338-0.0684,0.2431-0.2773,0.3496
		c-1.9922,0.8984-5.9102,2.4131-11.0743,2.4131c-4.2304,0-8.7324-1.542-14.3925-4.6817L189.1257,180.3266z M184.4812,180.1929
		c3.1445,0,9.6348-1.8223,9.6348-11.9795c0-8.3848-5.0977-12.8877-10.6202-12.8877c-5.831,0-10.1923,3.8428-10.1923,11.4873
		C173.3035,174.9496,178.1931,180.1929,184.4812,180.1929z"
      />
      <path
        fill="#101010"
        d="M202.8562,167.1586c0-4.6045,0-5.4492-0.0684-6.3867c-0.0722-0.9434-0.248-1.5098-1.2558-1.6436
		c-0.2442-0.0351-0.7754-0.0693-1.0528-0.0693c-0.1074,0-0.2402-0.1396-0.2402-0.2432c0-0.1738,0.1328-0.249,0.4961-0.249
		c1.3867,0,3.2422,0.0752,3.5195,0.0752c0.2832,0,2.2012-0.0752,3.1133-0.0752c0.3438,0,0.5176,0.0752,0.5176,0.249
		c0,0.1036-0.1406,0.2432-0.2754,0.2432c-0.209,0-0.3848,0-0.7695,0.0693c-0.8008,0.1338-0.9805,0.6631-1.0469,1.6436
		c-0.0664,0.9375-0.0664,1.7822-0.0664,6.3867v4.1572c0,4.2998,0.9375,6.0821,2.2637,7.2686
		c1.5039,1.3652,2.8281,1.6084,4.5761,1.6084c1.8848,0,3.7032-0.8389,4.7481-2.1006c1.4336-1.707,1.748-4.1523,1.748-7.1933v-3.7403
		c0-4.6045-0.0312-5.4492-0.0644-6.3867c-0.0391-0.9434-0.211-1.5098-1.2285-1.6436c-0.2442-0.0351-0.7637-0.0693-1.0469-0.0693
		c-0.1367,0-0.2442-0.1396-0.2442-0.2432c0-0.1738,0.1426-0.249,0.4864-0.249c1.3632,0,3.1464,0.0752,3.3222,0.0752
		c0.2149,0,1.6758-0.0752,2.5821-0.0752c0.3535,0,0.4902,0.0752,0.4902,0.249c0,0.1036-0.1055,0.2432-0.2422,0.2432
		c-0.2148,0-0.3828,0-0.7695,0.0693c-0.8321,0.1739-1.0137,0.6631-1.0762,1.6436c-0.0781,0.9375-0.0449,1.7822-0.0449,6.3867v3.1797
		c0,3.2168-0.418,6.8164-2.8594,8.9111c-2.2324,1.9209-4.6113,2.1592-6.4336,2.1592c-1.0078,0-4.2226-0.0615-6.3848-2.0869
		c-1.5039-1.3975-2.6933-3.458-2.6933-7.7949V167.1586z"
      />
      <path
        fill="#101010"
        d="M233.2546,159.1685c0.3828-0.9863,0.5196-1.1602,0.7305-1.1602c0.3105,0,0.4238,0.418,0.7285,1.085
		c0.5664,1.2558,5.4883,13.5215,7.375,18.0215c1.1192,2.6611,1.959,3.0429,2.627,3.2539c0.4472,0.1367,0.8984,0.1689,1.2168,0.1689
		c0.1757,0,0.3847,0.0723,0.3847,0.251c0,0.1689-0.3515,0.2432-0.7031,0.2432c-0.4512,0-2.7227,0-4.8555-0.0743
		c-0.5879-0.0273-1.082-0.0273-1.082-0.2041c0-0.1435,0.0742-0.1435,0.2148-0.2158c0.17-0.0664,0.4883-0.3154,0.2793-0.7969
		l-2.7636-6.8164c-0.0723-0.1386-0.0996-0.1738-0.2774-0.1738h-7.0898c-0.1406,0-0.2422,0.0674-0.3164,0.2442l-1.7461,4.7558
		c-0.2832,0.7266-0.457,1.4639-0.457,1.9902c0,0.586,0.5273,0.7969,1.0488,0.7969h0.2871c0.2383,0,0.3047,0.1094,0.3047,0.251
		c0,0.1689-0.1739,0.2432-0.4453,0.2432c-0.7383,0-2.4161-0.1016-2.7637-0.1016c-0.3106,0-1.8535,0.1016-3.1055,0.1016
		c-0.3926,0-0.5937-0.0743-0.5937-0.2432c0-0.1416,0.1347-0.251,0.2715-0.251c0.2128,0,0.6679-0.0322,0.9101-0.0664
		c1.3985-0.1719,1.9922-1.2217,2.5918-2.7207L233.2546,159.1685z M236.6785,171.5269c0.1367,0,0.1367-0.0664,0.1015-0.211
		l-3.039-8.1367c-0.1758-0.4521-0.3497-0.4521-0.5293,0l-2.8555,8.1367c-0.0371,0.1446,0,0.211,0.1035,0.211H236.6785z"
      />
      <path
        fill="#101010"
        d="M250.7937,176.976c0.0683,2.4424,0.3437,3.1816,1.1816,3.3926c0.5625,0.1367,1.2637,0.1689,1.5391,0.1689
		c0.1426,0,0.2519,0.1094,0.2519,0.251c0,0.1689-0.2168,0.2432-0.5625,0.2432c-1.746,0-2.9648-0.1016-3.248-0.1016
		s-1.5742,0.1016-2.9727,0.1016c-0.3164,0-0.4902-0.0401-0.4902-0.2432c0-0.1416,0.1113-0.251,0.2481-0.251
		c0.2421,0,0.8046-0.0322,1.25-0.1689c0.7773-0.211,0.8828-1.0147,0.8828-3.7061v-17.0127c0-1.1797,0.1777-1.5,0.4179-1.5
		c0.3457,0,0.8731,0.5957,1.1817,0.9092c0.4941,0.4863,4.9609,5.2373,9.7148,10.1328c3.0332,3.1446,6.3223,6.6719,7.2637,7.6446
		l-0.3086-14.879c-0.0371-1.9228-0.2148-2.5859-1.1543-2.7597c-0.5625-0.1045-1.2617-0.1387-1.5-0.1387
		c-0.2129,0-0.248-0.1738-0.248-0.2832c0-0.1738,0.248-0.209,0.5937-0.209c1.3965,0,2.8555,0.0752,3.2148,0.0752
		c0.3497,0,1.3575-0.0752,2.6133-0.0752c0.3203,0,0.5606,0.0352,0.5606,0.209c0,0.1094-0.1367,0.2832-0.3496,0.2832
		c-0.1368,0-0.3848,0-0.7325,0.0693c-1.0097,0.2081-1.08,0.8038-1.08,2.5811l-0.0723,17.4014c0,1.9531-0.0352,2.1298-0.2422,2.1298
		c-0.3183,0-0.6308-0.249-2.3398-1.8222c-0.3223-0.2735-4.7481-4.6416-8.002-8.0332c-3.5605-3.7324-7.0176-7.3672-7.9629-8.4141
		L250.7937,176.976z"
      />
      <path
        fill="#101010"
        d="M277.491,178.2417c-3.0137-2.5869-3.7481-5.9775-3.7481-8.8076c0-1.9922,0.7344-5.4502,3.4649-8.1064
		c1.8476-1.7823,4.6445-3.1778,9.3593-3.1778c1.2227,0,2.9649,0.1045,4.4688,0.3477c1.1504,0.2109,2.1347,0.4219,3.1426,0.4541
		c0.3183,0,0.3847,0.1416,0.3847,0.3105c0,0.251-0.0664,0.6006-0.1347,1.6836c-0.0352,0.9776-0.0352,2.6182-0.0723,3.0381
		c-0.0352,0.4512-0.1777,0.6279-0.3477,0.6279c-0.209,0-0.2461-0.206-0.2461-0.6279c0-1.1572-0.4882-2.3437-1.2929-2.9668
		c-1.0801-0.9111-3.3223-1.6455-6.045-1.6455c-3.9433,0-5.8281,1.0527-6.9082,2.0606c-2.2753,2.0996-2.7617,4.7558-2.7617,7.7597
		c0,5.6914,4.5,10.7578,11.0684,10.7578c2.3047,0,3.8437-0.1738,5.0664-1.3955c0.6621-0.6699,1.0781-1.9209,1.1875-2.4824
		c0.0723-0.3174,0.1016-0.417,0.3164-0.417c0.1367,0,0.2773,0.209,0.2773,0.417c0,0.2041-0.4238,2.79-0.7343,3.8037
		c-0.1739,0.5606-0.2442,0.6621-0.7657,0.8779c-1.2558,0.4873-3.5586,0.6553-5.5566,0.6553
		C283.0027,181.4086,279.8972,180.3266,277.491,178.2417z"
      />
      <path
        fill="#101010"
        d="M305.782,159.1685c0.3847-0.9863,0.5254-1.1602,0.7343-1.1602c0.3125,0,0.42,0.418,0.7325,1.085
		c0.5586,1.2558,5.4804,13.5215,7.3672,18.0215c1.1269,2.6611,1.9589,3.0429,2.6269,3.2539
		c0.4453,0.1367,0.9043,0.1689,1.2227,0.1689c0.1738,0,0.3769,0.0723,0.3769,0.251c0,0.1689-0.3437,0.2432-0.6953,0.2432
		c-0.459,0-2.7305,0-4.8594-0.0743c-0.5898-0.0273-1.082-0.0273-1.082-0.2041c0-0.1435,0.0801-0.1435,0.2129-0.2158
		c0.1758-0.0664,0.4941-0.3154,0.2793-0.7969l-2.7559-6.8164c-0.0742-0.1386-0.1074-0.1738-0.2832-0.1738h-7.0918
		c-0.1367,0-0.2441,0.0674-0.3144,0.2442l-1.7461,4.7558c-0.2774,0.7266-0.4551,1.4639-0.4551,1.9902
		c0,0.586,0.5293,0.7969,1.0488,0.7969h0.2832c0.2461,0,0.3106,0.1094,0.3106,0.251c0,0.1689-0.1699,0.2432-0.4531,0.2432
		c-0.7344,0-2.4141-0.1016-2.754-0.1016c-0.3183,0-1.8574,0.1016-3.1132,0.1016c-0.3848,0-0.5938-0.0743-0.5938-0.2432
		c0-0.1416,0.1406-0.251,0.2832-0.251c0.2031,0,0.6602-0.0322,0.8985-0.0664c1.4023-0.1719,1.9941-1.2217,2.5918-2.7207
		L305.782,159.1685z M309.2038,171.5269c0.1426,0,0.1426-0.0664,0.1094-0.211l-3.041-8.1367c-0.1797-0.4521-0.3496-0.4521-0.5254,0
		l-2.8652,8.1367c-0.0371,0.1446,0,0.211,0.1054,0.211H309.2038z"
      />
      <path
        fill="#101010"
        d="M322.0359,167.1586c0-4.6045,0-5.4492-0.0723-6.3867c-0.0742-1.0156-0.25-1.5098-1.2578-1.6436
		c-0.2441-0.0351-0.7676-0.0693-1.0508-0.0693c-0.1035,0-0.2461-0.1396-0.2461-0.2432c0-0.1738,0.1426-0.249,0.4922-0.249
		c1.3945,0,3.4531,0.0752,3.6328,0.0752c0.3145,0,2.9004-0.0752,3.8379-0.0752c1.959,0,4.125,0.1787,5.7266,1.2959
		c0.7695,0.5615,2.1406,1.9961,2.1406,4.0459c0,2.2022-0.916,4.3369-3.7812,6.7822c2.5195,3.1475,4.6464,5.8331,6.4296,7.6797
		c1.6485,1.6826,2.6563,1.9561,3.4219,2.0655c0.5957,0.1015,0.9063,0.1015,1.084,0.1015c0.1836,0,0.2773,0.1416,0.2773,0.251
		c0,0.1689-0.1718,0.2432-0.7304,0.2432h-1.9239c-1.7421,0-2.5136-0.1787-3.2793-0.5957c-1.332-0.6954-2.4492-2.1348-4.1992-4.3643
		c-1.2812-1.6055-2.6504-3.5293-3.3086-4.334c-0.1464-0.1387-0.2089-0.1728-0.4238-0.1728l-3.9121-0.0703
		c-0.1367,0-0.209,0.0703-0.209,0.2431v0.7031c0,2.8623,0,5.1651,0.1485,6.3868c0.0937,0.874,0.2714,1.4326,1.2089,1.5683
		c0.42,0.0752,1.0879,0.1416,1.3711,0.1416c0.211,0,0.2383,0.1416,0.2383,0.251c0,0.1416-0.1308,0.2432-0.4941,0.2432
		c-1.668,0-3.6231-0.1016-3.7637-0.1016c-0.0391,0-2.0977,0.1016-3.0391,0.1016c-0.3164,0-0.4941-0.0743-0.4941-0.2432
		c0-0.1094,0.0781-0.251,0.2519-0.251c0.2774,0,0.6622-0.0664,0.9375-0.1416c0.5938-0.1357,0.6641-0.6943,0.8047-1.5683
		c0.1817-1.2217,0.1817-3.5577,0.1817-6.4287V167.1586z M324.6843,169.6734c0,0.1787,0.0723,0.2822,0.209,0.3916
		c0.459,0.2412,2.0273,0.4521,3.3926,0.4521c0.7344,0,1.5644-0.0351,2.3008-0.5215c1.0468-0.6953,1.8574-2.2763,1.8574-4.5449
		c0-3.7012-2.0332-5.831-5.3145-5.831c-0.9062,0-1.8789,0.1044-2.2031,0.1718c-0.1328,0.0713-0.2422,0.1807-0.2422,0.3496V169.6734z
		"
      />
      <path
        fill="#101010"
        d="M344.6335,167.1586c0-4.6045,0-5.4492-0.0683-6.3867c-0.0723-1.0156-0.2461-1.5098-1.2539-1.6436
		c-0.2461-0.0351-0.7715-0.0693-1.0547-0.0693c-0.1016,0-0.2442-0.1396-0.2442-0.2432c0-0.1738,0.1426-0.249,0.4942-0.249
		c1.3984,0,3.3828,0.0752,3.5586,0.0752c0.3515,0,2.3047-0.0752,4.1211-0.0752c3.0742,0,8.705-0.2754,12.332,3.3906
		c1.5391,1.5391,3.0059,4.0196,3.0059,7.544c0,3.7363-1.6075,6.6006-3.3243,8.3476c-1.3222,1.3653-4.0839,3.4258-9.3203,3.4258
		c-1.3359,0-2.9707-0.1045-4.2968-0.1738c-1.3301-0.1094-2.3809-0.1709-2.5176-0.1709c-0.0664,0-0.6231,0.0273-1.2559,0.0273
		c-0.6269,0.0342-1.3652,0.0743-1.8515,0.0743c-0.3165,0-0.4903-0.0743-0.4903-0.2432c0-0.1094,0.0723-0.251,0.25-0.251
		c0.2754,0,0.6602-0.0664,0.9434-0.1416c0.5937-0.1357,0.6582-0.6943,0.7988-1.5683c0.1738-1.2217,0.1738-3.5577,0.1738-6.4287
		V167.1586z M347.4324,170.3725c0,3.1533,0,5.9033,0.039,6.4639c0.0313,0.7353,0.0938,1.8222,0.3418,2.1298
		c0.3848,0.5567,1.4668,1.1914,5.0938,1.1914c2.8359,0,5.3496-0.9433,7.2011-2.6904c1.6055-1.5019,2.4102-4.332,2.4102-7.0596
		c0-3.7685-1.6406-6.1738-2.8984-7.4365c-2.8946-2.9365-6.2891-3.3213-9.9551-3.3213c-0.5918,0-1.5703,0.1065-1.8516,0.2129
		c-0.2773,0.1094-0.3808,0.2432-0.3808,0.5889V170.3725z"
      />
    </g>
    <g>
      <path
        fill="#101010"
        d="M26.6736,216.8949c-1.8643-1.6679-2.3262-3.8593-2.3262-5.6835c0-1.2862,0.4443-3.5352,2.1523-5.2432
		c1.1543-1.1543,2.9014-2.0547,5.8584-2.0547c0.7696,0,1.8438,0.0566,2.7852,0.2305c0.7324,0.1338,1.3447,0.248,1.9609,0.2881
		c0.208,0.0175,0.2481,0.0937,0.2481,0.2109c0,0.1543-0.0567,0.3848-0.0967,1.0752c-0.0371,0.6348-0.0371,1.6904-0.0576,1.9775
		c-0.0195,0.291-0.0567,0.4053-0.1738,0.4053c-0.1338,0-0.1514-0.1367-0.1514-0.4053c0-0.747-0.3076-1.5361-0.8066-1.958
		c-0.6749-0.5762-2.1514-1.0947-3.8418-1.0947c-2.5547,0-3.7461,0.75-4.419,1.4004c-1.4023,1.3447-1.7305,3.0556-1.7305,5.0137
		c0,3.6689,2.8252,7.0322,6.9385,7.0322c1.4375,0,2.5713-0.1738,3.3408-0.9629c0.3995-0.4219,0.6534-1.2666,0.71-1.6113
		c0.04-0.2129,0.0771-0.2881,0.1914-0.2881c0.0967,0,0.1367,0.1347,0.1367,0.2881c0,0.1338-0.2334,1.8046-0.4248,2.458
		c-0.1162,0.3642-0.1513,0.4043-0.499,0.5556c-0.7666,0.3106-2.2266,0.4424-3.4551,0.4424
		C30.1287,218.9711,28.1902,218.2603,26.6736,216.8949z"
      />
      <path
        fill="#101010"
        d="M51.5134,206.1225c3.8057,0,6.6846,2.3232,6.6846,6.126c0,3.6494-2.7051,6.665-6.7783,6.665
		c-4.627,0-6.6084-3.4775-6.6084-6.4141C44.8113,209.8676,46.8494,206.1225,51.5134,206.1225z M51.8982,218.2437
		c1.5195,0,4.5752-0.8067,4.5752-5.5332c0-3.917-2.3828-5.9746-5.0537-5.9746c-2.8223,0-4.8584,1.7666-4.8584,5.3213
		C46.5613,215.8598,48.8454,218.2437,51.8982,218.2437z"
      />
      <path
        fill="#101010"
        d="M66.7742,211.0766c0-2.5361,0-2.9961-0.0372-3.5147c-0.039-0.5185-0.1337-0.8261-0.6923-0.9033
		c-0.1338-0.0195-0.4219-0.0371-0.5762-0.0371c-0.0596,0-0.1338-0.0771-0.1338-0.1338c0-0.0976,0.0742-0.1347,0.2686-0.1347
		c0.7666,0,1.7871,0.0371,1.9384,0.0371c0.1534,0,1.211-0.0371,1.71-0.0371c0.1914,0,0.2881,0.0371,0.2881,0.1347
		c0,0.0567-0.0742,0.1338-0.1543,0.1338c-0.1143,0-0.211,0-0.4219,0.0371c-0.4414,0.0772-0.5361,0.3653-0.5752,0.9033
		c-0.04,0.5186-0.04,0.9786-0.04,3.5147v2.2871c0,2.3603,0.5185,3.3408,1.251,3.9932c0.8242,0.75,1.5537,0.8867,2.5146,0.8867
		c1.0371,0,2.0352-0.4619,2.6133-1.1543c0.7871-0.9414,0.959-2.2842,0.959-3.9571v-2.0556c0-2.5361-0.0176-2.9961-0.0381-3.5147
		c-0.0195-0.5185-0.1162-0.8261-0.6719-0.9033c-0.1348-0.0195-0.4219-0.0371-0.5762-0.0371c-0.0771,0-0.1338-0.0771-0.1338-0.1338
		c0-0.0976,0.0762-0.1347,0.2706-0.1347c0.747,0,1.7246,0.0371,1.8212,0.0371c0.1172,0,0.9239-0.0371,1.4229-0.0371
		c0.1914,0,0.2676,0.0371,0.2676,0.1347c0,0.0567-0.0567,0.1338-0.1338,0.1338c-0.1143,0-0.211,0-0.4219,0.0371
		c-0.4619,0.0967-0.5586,0.3653-0.5957,0.9033c-0.04,0.5186-0.0176,0.9786-0.0176,3.5147v1.75c0,1.7656-0.2334,3.7441-1.5761,4.8984
		c-1.2286,1.0547-2.5372,1.1885-3.5342,1.1885c-0.5586,0-2.3262-0.04-3.5156-1.1514c-0.8262-0.7695-1.4795-1.9023-1.4795-4.2822
		V211.0766z"
      />
      <path
        fill="#101010"
        d="M87.0818,211.0766c0-2.5361,0-2.9961-0.0342-3.5147c-0.04-0.5556-0.1367-0.8261-0.6934-0.9033
		c-0.1338-0.0195-0.4218-0.0371-0.5752-0.0371c-0.0595,0-0.1347-0.0771-0.1347-0.1338c0-0.0976,0.0752-0.1347,0.2656-0.1347
		c0.7695,0,1.9043,0.0371,1.999,0.0371c0.1739,0,1.5957-0.0371,2.1143-0.0371c1.0752,0,2.2666,0.0947,3.1504,0.7099
		c0.4218,0.3086,1.1718,1.0928,1.1718,2.2266c0,1.2119-0.5019,2.3828-2.0751,3.7285c1.3818,1.7275,2.5537,3.207,3.5341,4.2246
		c0.9034,0.9209,1.46,1.0781,1.8819,1.1348c0.3271,0.0566,0.5009,0.0566,0.5957,0.0566c0.0967,0,0.1543,0.0772,0.1543,0.1348
		c0,0.0967-0.0948,0.1367-0.4053,0.1367h-1.0547c-0.9609,0-1.3828-0.0977-1.8047-0.3281c-0.7324-0.3848-1.3457-1.1719-2.3086-2.4004
		c-0.7099-0.8838-1.459-1.9414-1.8222-2.3828c-0.0762-0.0772-0.1163-0.0967-0.2305-0.0967l-2.1524-0.0371
		c-0.0771,0-0.1142,0.0371-0.1142,0.1338v0.3847c0,1.5762,0,2.8418,0.0771,3.5147c0.0577,0.4795,0.1543,0.7871,0.67,0.8642
		c0.2314,0.0371,0.5957,0.0762,0.75,0.0762c0.1171,0,0.1337,0.0772,0.1337,0.1348c0,0.0761-0.0742,0.1367-0.2675,0.1367
		c-0.9209,0-1.9981-0.0606-2.0752-0.0606c-0.0176,0-1.1524,0.0606-1.6709,0.0606c-0.1739,0-0.2676-0.04-0.2676-0.1367
		c0-0.0576,0.0391-0.1348,0.1338-0.1348c0.1533,0,0.3652-0.0391,0.5185-0.0762c0.3282-0.0771,0.3653-0.3847,0.4424-0.8642
		c0.0938-0.6729,0.0938-1.961,0.0938-3.5342V211.0766z M88.5437,212.4595c0,0.0967,0.0371,0.1543,0.1142,0.2109
		c0.251,0.1348,1.1153,0.251,1.8643,0.251c0.4023,0,0.8643-0.0195,1.2686-0.2881c0.5761-0.3847,1.0156-1.248,1.0156-2.5
		c0-2.0322-1.1123-3.207-2.9199-3.207c-0.4981,0-1.0371,0.0576-1.208,0.0977c-0.0772,0.039-0.1348,0.0966-0.1348,0.1933V212.4595z"
      />
      <path
        fill="#101010"
        d="M108.1785,213.9595c0,1.5732,0,2.8613,0.08,3.5342c0.0567,0.4795,0.1338,0.7871,0.6524,0.8642
		c0.2304,0.0371,0.6152,0.0762,0.7676,0.0762c0.1132,0,0.1357,0.0772,0.1357,0.1348c0,0.0761-0.0791,0.1367-0.2705,0.1367
		c-0.9229,0-1.9951-0.0606-2.0928-0.0606c-0.0762,0-1.248,0.0606-1.75,0.0606c-0.1904,0-0.2676-0.04-0.2676-0.1367
		c0-0.0576,0.0371-0.1172,0.1338-0.1172c0.1543,0,0.3477-0.0342,0.4991-0.0742c0.3447-0.0772,0.4443-0.4043,0.5019-0.8838
		c0.0742-0.6729,0.0742-1.961,0.0742-3.5342v-6.8594l-2.4404,0.0401c-1.0547,0.0195-1.459,0.1338-1.708,0.5185
		c-0.1904,0.2881-0.211,0.4024-0.2705,0.5195c-0.0342,0.1338-0.0938,0.1534-0.1709,0.1534c-0.0371,0-0.1162-0.0401-0.1162-0.1368
		c0-0.1708,0.3466-1.6328,0.3672-1.7646c0.0371-0.0996,0.1142-0.3076,0.1709-0.3076c0.1171,0,0.29,0.1338,0.7519,0.1914
		c0.4795,0.0566,1.1318,0.0762,1.3232,0.0762h6.3799c0.5528,0,0.9405-0.0371,1.2061-0.0762c0.2529-0.04,0.4043-0.0772,0.4814-0.0772
		c0.0772,0,0.0772,0.0938,0.0772,0.1934c0,0.4961-0.0576,1.6709-0.0576,1.8613c0,0.1348-0.0762,0.211-0.1534,0.211
		c-0.0771,0-0.1142-0.0567-0.1337-0.2852l-0.0206-0.1738c-0.0566-0.501-0.4238-0.8662-2.0351-0.9033l-2.1152-0.0401V213.9595z"
      />
      <path
        fill="#101010"
        d="M123.1296,213.9595c0,1.5732,0,2.8613,0.0772,3.5342c0.0566,0.4795,0.1133,0.7871,0.6152,0.8642
		c0.2481,0.0371,0.6133,0.0762,0.7666,0.0762c0.0967,0,0.1348,0.0772,0.1348,0.1348c0,0.0761-0.0772,0.1367-0.2686,0.1367
		c-0.9433,0-2.0351-0.0606-2.1123-0.0606c-0.0967,0-1.1513,0.0606-1.6709,0.0606c-0.1738,0-0.2705-0.04-0.2705-0.1367
		c0-0.0576,0.0401-0.1348,0.1367-0.1348c0.1543,0,0.3653-0.0391,0.5166-0.0762c0.3272-0.0771,0.3848-0.3847,0.4414-0.8642
		c0.0772-0.6729,0.0967-1.961,0.0967-3.5342v-2.8828c0-2.5361,0-2.9961-0.039-3.5147c-0.0352-0.5556-0.1719-0.8066-0.5362-0.8838
		c-0.1914-0.039-0.4053-0.0566-0.5195-0.0566c-0.0762,0-0.1358-0.0771-0.1358-0.1338c0-0.0976,0.0792-0.1347,0.2706-0.1347
		c0.5586,0,1.6132,0.0371,1.7099,0.0371c0.0772,0,1.169-0.0371,1.6875-0.0371c0.1944,0,0.2705,0.0371,0.2705,0.1347
		c0,0.0567-0.0761,0.1338-0.1338,0.1338c-0.1142,0-0.2304,0-0.4218,0.0371c-0.462,0.0967-0.5586,0.3252-0.5957,0.9033
		c-0.0196,0.5186-0.0196,0.9786-0.0196,3.5147V213.9595z"
      />
      <path
        fill="#101010"
        d="M134.0613,211.0766c0-2.5361,0-2.9961-0.0352-3.5147c-0.0391-0.5556-0.1357-0.8261-0.6924-0.9033
		c-0.1367-0.0195-0.4238-0.0371-0.5752-0.0371c-0.0595,0-0.1367-0.0771-0.1367-0.1338c0-0.0976,0.0772-0.1347,0.2705-0.1347
		c0.7666,0,1.8614,0.0371,1.958,0.0371c0.1172,0,3.6494,0.0205,4.0342,0c0.3272-0.0196,0.6348-0.0762,0.7666-0.0938
		c0.0772-0.0224,0.1563-0.0791,0.2315-0.0791c0.0595,0,0.0761,0.0791,0.0761,0.1533c0,0.1172-0.0937,0.3076-0.1338,0.7696
		c-0.0175,0.1543-0.0566,0.8838-0.0966,1.0771c-0.0176,0.0742-0.0772,0.1719-0.1514,0.1719c-0.0967,0-0.1367-0.0772-0.1367-0.211
		c0-0.1171-0.0176-0.3847-0.0938-0.5761c-0.1172-0.2705-0.2881-0.3848-1.2119-0.502c-0.2881-0.0371-2.249-0.0566-2.4394-0.0566
		c-0.0752,0-0.0948,0.0566-0.0948,0.1738v4.4385c0,0.1113,0,0.1709,0.0948,0.1709c0.2128,0,2.4394,0,2.8242-0.0371
		c0.4043-0.0401,0.6533-0.0596,0.8271-0.2305c0.1133-0.1172,0.1905-0.2139,0.2481-0.2139c0.0566,0,0.0967,0.0401,0.0967,0.1368
		c0,0.0937-0.0967,0.3642-0.1368,0.9003c-0.0351,0.3282-0.0742,0.9415-0.0742,1.0577c0,0.1367-0.04,0.3056-0.1562,0.3056
		c-0.0752,0-0.1123-0.0576-0.1123-0.1513c0-0.1543,0-0.3282-0.0791-0.5362c-0.0567-0.2314-0.209-0.4248-0.8643-0.5019
		c-0.4609-0.0567-2.2637-0.0762-2.5518-0.0762c-0.0966,0-0.1162,0.0566-0.1162,0.1162v1.3633c0,0.5576-0.0195,2.457,0,2.7852
		c0.0371,1.0937,0.3448,1.3046,2.0157,1.3046c0.4414,0,1.2509,0,1.6904-0.1738c0.4414-0.1904,0.6728-0.4785,0.7695-1.1308
		c0.0371-0.1944,0.0772-0.2491,0.1709-0.2491c0.0967,0,0.1172,0.1338,0.1172,0.2491c0,0.1162-0.1348,1.2285-0.2314,1.5761
		c-0.1163,0.4219-0.2881,0.4219-0.9405,0.4219c-1.2685,0-2.2461-0.0371-2.9394-0.0596c-0.71-0.0381-1.1514-0.0381-1.4024-0.0381
		c-0.039,0-0.3642,0-0.7295,0.0206c-0.3252,0.0175-0.7099,0.04-0.9804,0.04c-0.1709,0-0.2686-0.04-0.2686-0.1367
		c0-0.0576,0.0381-0.1348,0.1348-0.1348c0.1533,0,0.3672-0.0566,0.5185-0.0762c0.3252-0.0576,0.3653-0.3847,0.4414-0.8642
		c0.0948-0.6729,0.0948-1.961,0.0948-3.5342V211.0766z"
      />
      <path
        fill="#101010"
        d="M150.032,211.0766c0-2.5361,0-2.9961-0.0391-3.5147c-0.04-0.5556-0.1367-0.8261-0.6934-0.9033
		c-0.1308-0.0195-0.4218-0.0371-0.5751-0.0371c-0.0577,0-0.1348-0.0771-0.1348-0.1338c0-0.0976,0.0771-0.1347,0.2685-0.1347
		c0.7696,0,1.9034,0.0371,2.001,0.0371c0.168,0,1.5908-0.0371,2.1123-0.0371c1.0723,0,2.2656,0.0947,3.1494,0.7099
		c0.4219,0.3086,1.1719,1.0928,1.1719,2.2266c0,1.2119-0.501,2.3828-2.0752,3.7285c1.3828,1.7275,2.5547,3.207,3.5352,4.2246
		c0.9004,0.9209,1.459,1.0781,1.8808,1.1348c0.3282,0.0566,0.4991,0.0566,0.5957,0.0566c0.0948,0,0.1543,0.0772,0.1543,0.1348
		c0,0.0967-0.0966,0.1367-0.4043,0.1367h-1.0556c-0.9629,0-1.3848-0.0977-1.8067-0.3281c-0.7275-0.3848-1.3457-1.1719-2.3056-2.4004
		c-0.71-0.8838-1.4571-1.9414-1.8252-2.3828c-0.0742-0.0772-0.1143-0.0967-0.2305-0.0967l-2.1494-0.0371
		c-0.0772,0-0.1172,0.0371-0.1172,0.1338v0.3847c0,1.5762,0,2.8418,0.0801,3.5147c0.0566,0.4795,0.1513,0.7871,0.6699,0.8642
		c0.2305,0.0371,0.5957,0.0762,0.75,0.0762c0.1133,0,0.1338,0.0772,0.1338,0.1348c0,0.0761-0.0772,0.1367-0.2686,0.1367
		c-0.9228,0-1.9951-0.0606-2.0752-0.0606c-0.0175,0-1.1513,0.0606-1.6699,0.0606c-0.1719,0-0.2685-0.04-0.2685-0.1367
		c0-0.0576,0.0371-0.1348,0.1338-0.1348c0.1543,0,0.3652-0.0391,0.5195-0.0762c0.3252-0.0771,0.3623-0.3847,0.4414-0.8642
		c0.0967-0.6729,0.0967-1.961,0.0967-3.5342V211.0766z M151.489,212.4595c0,0.0967,0.04,0.1543,0.1172,0.2109
		c0.25,0.1348,1.1113,0.251,1.8613,0.251c0.4043,0,0.8662-0.0195,1.2686-0.2881c0.5761-0.3847,1.0205-1.248,1.0205-2.5
		c0-2.0322-1.1172-3.207-2.9219-3.207c-0.499,0-1.0352,0.0576-1.209,0.0977c-0.0791,0.039-0.1367,0.0966-0.1367,0.1933V212.4595z"
      />
      <path
        fill="#101010"
        d="M182.5222,206.6781c0.209-0.538,0.2861-0.6347,0.4023-0.6347c0.1709,0,0.2305,0.2305,0.4024,0.5957
		c0.3076,0.6924,3.0156,7.4336,4.0527,9.9111c0.6133,1.4619,1.0742,1.6729,1.4395,1.7871c0.2519,0.0801,0.5019,0.0967,0.6738,0.0967
		c0.0957,0,0.2129,0.0371,0.2129,0.1348c0,0.0967-0.1934,0.1367-0.3848,0.1367c-0.25,0-1.4961,0-2.6679-0.04
		c-0.3282-0.0206-0.5987-0.0206-0.5987-0.1172c0-0.0772,0.0401-0.0772,0.1172-0.1143c0.0967-0.0391,0.2676-0.1738,0.1533-0.4414
		l-1.5166-3.7461c-0.039-0.0762-0.0595-0.0967-0.1562-0.0967h-3.8994c-0.0742,0-0.1338,0.0401-0.1709,0.1367l-0.961,2.6084
		c-0.1543,0.4053-0.248,0.8096-0.248,1.0977c0,0.3281,0.2851,0.4414,0.5732,0.4414h0.1534c0.1347,0,0.1738,0.0576,0.1738,0.1348
		c0,0.0967-0.0967,0.1367-0.2481,0.1367c-0.4043,0-1.3252-0.0606-1.5185-0.0606c-0.1719,0-1.0186,0.0606-1.711,0.0606
		c-0.208,0-0.3252-0.04-0.3252-0.1367c0-0.0772,0.0772-0.1348,0.1514-0.1348c0.1192,0,0.3682-0.0166,0.502-0.0391
		c0.7666-0.0947,1.0947-0.6699,1.4199-1.5L182.5222,206.6781z M184.404,213.4799c0.0772,0,0.0772-0.04,0.0567-0.1162l-1.6699-4.4766
		c-0.0948-0.248-0.1914-0.248-0.2881,0l-1.5762,4.4766c-0.0176,0.0762,0,0.1162,0.0566,0.1162H184.404z"
      />
      <path
        fill="#101010"
        d="M196.0671,218.4711c-0.2304-0.1133-0.248-0.1709-0.248-0.6123c0-0.8066,0.0742-1.4599,0.0957-1.7305
		c0-0.1738,0.039-0.2685,0.1367-0.2685c0.0957,0,0.1523,0.0576,0.1523,0.1914c0,0.1348,0,0.3652,0.0586,0.5957
		c0.2676,1.1914,1.5352,1.6133,2.668,1.6133c1.6699,0,2.5371-0.9405,2.5371-2.169c0-1.1738-0.6367-1.7304-2.1347-2.8427
		l-0.7676-0.5782c-1.8242-1.3623-2.418-2.3603-2.418-3.4951c0-1.9209,1.5176-3.0722,3.7441-3.0722
		c0.6719,0,1.3438,0.0966,1.75,0.1933c0.3243,0.0742,0.459,0.0742,0.5918,0.0742c0.1348,0,0.1739,0.0196,0.1739,0.1172
		c0,0.0742-0.0782,0.6123-0.0782,1.7295c0,0.2481-0.0175,0.3623-0.1347,0.3623c-0.1133,0-0.1309-0.0771-0.1504-0.2109
		c-0.0215-0.1905-0.1543-0.6348-0.252-0.8067c-0.1172-0.1904-0.6718-0.8066-2.1894-0.8066c-1.2285,0-2.2481,0.6162-2.2481,1.7676
		c0,1.0371,0.5176,1.6328,2.1914,2.7646l0.4786,0.3281c2.0566,1.4024,2.7656,2.5342,2.7656,3.9395
		c0,0.958-0.3653,1.9961-1.5742,2.7822c-0.711,0.4619-1.7676,0.5762-2.6895,0.5762
		C197.741,218.9135,196.7605,218.7993,196.0671,218.4711z"
      />
      <path
        fill="#101010"
        d="M211.5183,218.4711c-0.2324-0.1133-0.252-0.1709-0.252-0.6123c0-0.8066,0.0782-1.4599,0.0957-1.7305
		c0-0.1738,0.0391-0.2685,0.1348-0.2685c0.0977,0,0.1524,0.0576,0.1524,0.1914c0,0.1348,0,0.3652,0.0586,0.5957
		c0.2695,1.1914,1.5371,1.6133,2.6699,1.6133c1.6718,0,2.5371-0.9405,2.5371-2.169c0-1.1738-0.6348-1.7304-2.1328-2.8427
		l-0.7676-0.5782c-1.8242-1.3623-2.4199-2.3603-2.4199-3.4951c0-1.9209,1.5175-3.0722,3.7461-3.0722
		c0.6699,0,1.3418,0.0966,1.748,0.1933c0.3242,0.0742,0.459,0.0742,0.5957,0.0742c0.1328,0,0.1699,0.0196,0.1699,0.1172
		c0,0.0742-0.0761,0.6123-0.0761,1.7295c0,0.2481-0.0196,0.3623-0.1348,0.3623c-0.1172,0-0.1328-0.0771-0.1504-0.2109
		c-0.0234-0.1905-0.1562-0.6348-0.2539-0.8067c-0.1133-0.1904-0.6699-0.8066-2.1875-0.8066c-1.2285,0-2.248,0.6162-2.248,1.7676
		c0,1.0371,0.5195,1.6328,2.1894,2.7646l0.4805,0.3281c2.0527,1.4024,2.7656,2.5342,2.7656,3.9395
		c0,0.958-0.3652,1.9961-1.5742,2.7822c-0.711,0.4619-1.7695,0.5762-2.6895,0.5762
		C213.1882,218.9135,212.2078,218.7993,211.5183,218.4711z"
      />
      <path
        fill="#101010"
        d="M228.0027,211.0766c0-2.5361,0-2.9961-0.041-3.5147c-0.0372-0.5556-0.1329-0.8261-0.6875-0.9033
		c-0.1368-0.0195-0.4239-0.0371-0.5801-0.0371c-0.0547,0-0.1348-0.0771-0.1348-0.1338c0-0.0976,0.0801-0.1347,0.2715-0.1347
		c0.7695,0,1.8633,0.0371,1.959,0.0371c0.1152,0,3.6504,0.0205,4.0351,0c0.3262-0.0196,0.6329-0.0762,0.7676-0.0938
		c0.0762-0.0224,0.1543-0.0791,0.2305-0.0791c0.0566,0,0.0762,0.0791,0.0762,0.1533c0,0.1172-0.0957,0.3076-0.1329,0.7696
		c-0.0195,0.1543-0.0605,0.8838-0.0976,1.0771c-0.0195,0.0742-0.0762,0.1719-0.1563,0.1719c-0.0937,0-0.1308-0.0772-0.1308-0.211
		c0-0.1171-0.0196-0.3847-0.0977-0.5761c-0.1152-0.2705-0.289-0.3848-1.209-0.502c-0.2871-0.0371-2.248-0.0566-2.4394-0.0566
		c-0.0762,0-0.0977,0.0566-0.0977,0.1738v4.4385c0,0.1113,0,0.1709,0.0977,0.1709c0.209,0,2.4394,0,2.8223-0.0371
		c0.4042-0.0401,0.6523-0.0596,0.8261-0.2305c0.1153-0.1172,0.1934-0.2139,0.252-0.2139c0.0566,0,0.0937,0.0401,0.0937,0.1368
		c0,0.0937-0.0937,0.3642-0.1347,0.9003c-0.0371,0.3282-0.0762,0.9415-0.0762,1.0577c0,0.1367-0.0371,0.3056-0.1543,0.3056
		c-0.0762,0-0.1133-0.0576-0.1133-0.1513c0-0.1543,0-0.3282-0.0781-0.5362c-0.0567-0.2314-0.211-0.4248-0.8633-0.5019
		c-0.4609-0.0567-2.2695-0.0762-2.5566-0.0762c-0.0938,0-0.1153,0.0566-0.1153,0.1162v1.3633c0,0.5576-0.0195,2.457,0,2.7852
		c0.041,1.0937,0.3457,1.3046,2.0196,1.3046c0.4394,0,1.2461,0,1.6894-0.1738c0.4414-0.1904,0.6699-0.4785,0.7676-1.1308
		c0.0391-0.1944,0.0762-0.2491,0.1738-0.2491c0.0957,0,0.1133,0.1338,0.1133,0.2491c0,0.1162-0.1328,1.2285-0.2305,1.5761
		c-0.1132,0.4219-0.2871,0.4219-0.9414,0.4219c-1.2676,0-2.2461-0.0371-2.9355-0.0596c-0.7129-0.0381-1.1543-0.0381-1.4043-0.0381
		c-0.0371,0-0.3653,0-0.7305,0.0206c-0.3242,0.0175-0.709,0.04-0.9805,0.04c-0.1718,0-0.2656-0.04-0.2656-0.1367
		c0-0.0576,0.0371-0.1348,0.1348-0.1348c0.1504,0,0.3613-0.0566,0.5176-0.0762c0.3261-0.0576,0.3632-0.3847,0.4394-0.8642
		c0.0977-0.6729,0.0977-1.961,0.0977-3.5342V211.0766z"
      />
      <path
        fill="#101010"
        d="M243.9714,211.0766c0-2.5361,0-2.9961-0.041-3.5147c-0.0371-0.5556-0.1328-0.8261-0.6914-0.9033
		c-0.1348-0.0195-0.4219-0.0371-0.5742-0.0371c-0.0586,0-0.1367-0.0771-0.1367-0.1338c0-0.0976,0.0781-0.1347,0.2714-0.1347
		c0.7657,0,1.8985,0.0371,1.9961,0.0371c0.1739,0,1.5918-0.0371,2.1114-0.0371c1.0781,0,2.2656,0.0947,3.1504,0.7099
		c0.4218,0.3086,1.1738,1.0928,1.1738,2.2266c0,1.2119-0.4981,2.3828-2.0742,3.7285c1.3808,1.7275,2.5508,3.207,3.5332,4.2246
		c0.9023,0.9209,1.457,1.0781,1.8828,1.1348c0.3242,0.0566,0.498,0.0566,0.5957,0.0566c0.0937,0,0.1543,0.0772,0.1543,0.1348
		c0,0.0967-0.0977,0.1367-0.4024,0.1367h-1.0586c-0.9609,0-1.3828-0.0977-1.8046-0.3281c-0.7325-0.3848-1.3457-1.1719-2.3047-2.4004
		c-0.711-0.8838-1.461-1.9414-1.8262-2.3828c-0.0781-0.0772-0.1152-0.0967-0.2305-0.0967l-2.1523-0.0371
		c-0.0742,0-0.1133,0.0371-0.1133,0.1338v0.3847c0,1.5762,0,2.8418,0.0742,3.5147c0.0606,0.4795,0.1563,0.7871,0.6758,0.8642
		c0.2305,0.0371,0.5918,0.0762,0.7461,0.0762c0.1172,0,0.1348,0.0772,0.1348,0.1348c0,0.0761-0.0743,0.1367-0.2676,0.1367
		c-0.9219,0-1.9981-0.0606-2.0742-0.0606c-0.0215,0-1.1543,0.0606-1.6719,0.0606c-0.1738,0-0.2676-0.04-0.2676-0.1367
		c0-0.0576,0.0371-0.1348,0.1328-0.1348c0.1524,0,0.3653-0.0391,0.5196-0.0762c0.3242-0.0771,0.3652-0.3847,0.4414-0.8642
		c0.0976-0.6729,0.0976-1.961,0.0976-3.5342V211.0766z M245.4304,212.4595c0,0.0967,0.0391,0.1543,0.1133,0.2109
		c0.2519,0.1348,1.1152,0.251,1.8652,0.251c0.4024,0,0.8633-0.0195,1.2676-0.2881c0.5742-0.3847,1.0156-1.248,1.0156-2.5
		c0-2.0322-1.1113-3.207-2.9199-3.207c-0.4961,0-1.0371,0.0576-1.207,0.0977c-0.0782,0.039-0.1348,0.0966-0.1348,0.1933V212.4595z"
      />
      <path
        fill="#101010"
        d="M274.9187,216.5903c0.0586,0.5761,0.1738,1.4589,0.8652,1.6904c0.4805,0.1533,0.9219,0.1533,1.0957,0.1533
		c0.0762,0,0.1348,0.0371,0.1348,0.1143c0,0.0966-0.1543,0.1572-0.3477,0.1572c-0.3828,0-2.2636-0.04-2.8789-0.0977
		c-0.4043-0.039-0.4785-0.0966-0.4785-0.1738c0-0.0566,0.0371-0.1133,0.1133-0.1338c0.0937-0.0195,0.1152-0.2705,0.0762-0.5752
		l-0.8067-8.2617h-0.039l-4.0743,8.3379c-0.3437,0.71-0.4375,0.8643-0.5957,0.8643c-0.1503,0-0.248-0.1543-0.5742-0.75
		c-0.4394-0.8067-1.5547-2.9014-1.9785-3.7852c-0.5-1.0381-1.8652-3.7832-2.1523-4.4531h-0.0743l-0.6543,7.375
		c-0.0195,0.248-0.0195,0.5556-0.0195,0.8232c0,0.251,0.1543,0.4053,0.4043,0.4619c0.2891,0.0801,0.5371,0.0967,0.6543,0.0967
		c0.0567,0,0.1328,0.0576,0.1328,0.1143c0,0.1172-0.1152,0.1572-0.3066,0.1572c-0.6328,0-1.4395-0.0606-1.5742-0.0606
		c-0.1563,0-0.961,0.0606-1.4258,0.0606c-0.1699,0-0.2871-0.04-0.2871-0.1572c0-0.0567,0.0801-0.1143,0.1738-0.1143
		c0.1367,0,0.25-0.0166,0.502-0.0566c0.5527-0.0967,0.5761-0.6924,0.6503-1.3057l1.2305-10.6611
		c0.0195-0.1739,0.0977-0.2881,0.1914-0.2881c0.1133,0,0.25,0.0547,0.3477,0.248l5.1074,10.1426l4.918-10.123
		c0.0761-0.1534,0.1543-0.2676,0.2675-0.2676c0.1172,0,0.2149,0.1338,0.252,0.4394L274.9187,216.5903z"
      />
      <path
        fill="#101010"
        d="M285.3972,211.0766c0-2.5361,0-2.9961-0.0391-3.5147c-0.0371-0.5556-0.1347-0.8261-0.6933-0.9033
		c-0.1348-0.0195-0.4219-0.0371-0.5742-0.0371c-0.0567,0-0.1348-0.0771-0.1348-0.1338c0-0.0976,0.0781-0.1347,0.2695-0.1347
		c0.7676,0,1.8614,0.0371,1.9571,0.0371c0.1152,0,3.6504,0.0205,4.0351,0c0.3262-0.0196,0.6348-0.0762,0.7695-0.0938
		c0.0743-0.0224,0.1524-0.0791,0.2286-0.0791c0.0605,0,0.0761,0.0791,0.0761,0.1533c0,0.1172-0.0937,0.3076-0.1328,0.7696
		c-0.0195,0.1543-0.0566,0.8838-0.0976,1.0771c-0.0176,0.0742-0.0743,0.1719-0.1504,0.1719c-0.0977,0-0.1367-0.0772-0.1367-0.211
		c0-0.1171-0.0176-0.3847-0.0977-0.5761c-0.1113-0.2705-0.2852-0.3848-1.209-0.502c-0.2871-0.0371-2.248-0.0566-2.4394-0.0566
		c-0.0743,0-0.0938,0.0566-0.0938,0.1738v4.4385c0,0.1113,0,0.1709,0.0938,0.1709c0.2129,0,2.4394,0,2.8222-0.0371
		c0.4043-0.0401,0.6563-0.0596,0.8262-0.2305c0.1172-0.1172,0.1934-0.2139,0.252-0.2139c0.0585,0,0.0957,0.0401,0.0957,0.1368
		c0,0.0937-0.0957,0.3642-0.1329,0.9003c-0.039,0.3282-0.0781,0.9415-0.0781,1.0577c0,0.1367-0.039,0.3056-0.1543,0.3056
		c-0.0761,0-0.1152-0.0576-0.1152-0.1513c0-0.1543,0-0.3282-0.0762-0.5362c-0.0586-0.2314-0.2129-0.4248-0.8652-0.5019
		c-0.461-0.0567-2.2637-0.0762-2.5528-0.0762c-0.0957,0-0.1152,0.0566-0.1152,0.1162v1.3633c0,0.5576-0.0215,2.457,0,2.7852
		c0.0371,1.0937,0.3438,1.3046,2.0156,1.3046c0.4434,0,1.25,0,1.6895-0.1738c0.4414-0.1904,0.6738-0.4785,0.7695-1.1308
		c0.0371-0.1944,0.0742-0.2491,0.1719-0.2491c0.0957,0,0.1172,0.1338,0.1172,0.2491c0,0.1162-0.1348,1.2285-0.2324,1.5761
		c-0.1153,0.4219-0.2871,0.4219-0.9395,0.4219c-1.2695,0-2.2461-0.0371-2.9394-0.0596c-0.711-0.0381-1.1524-0.0381-1.4043-0.0381
		c-0.0352,0-0.3614,0-0.7286,0.0206c-0.3281,0.0175-0.7089,0.04-0.9804,0.04c-0.1739,0-0.2676-0.04-0.2676-0.1367
		c0-0.0576,0.0371-0.1348,0.1348-0.1348c0.1523,0,0.3632-0.0566,0.5175-0.0762c0.3262-0.0576,0.3653-0.3847,0.4414-0.8642
		c0.0977-0.6729,0.0977-1.961,0.0977-3.5342V211.0766z"
      />
      <path
        fill="#101010"
        d="M302.073,216.4731c0.039,1.3457,0.1933,1.75,0.6523,1.8642c0.3106,0.0801,0.6934,0.0967,0.8477,0.0967
		c0.0762,0,0.1328,0.0576,0.1328,0.1348c0,0.0967-0.1133,0.1367-0.3066,0.1367c-0.961,0-1.6309-0.0606-1.7872-0.0606
		c-0.1523,0-0.8613,0.0606-1.6308,0.0606c-0.1738,0-0.2695-0.0225-0.2695-0.1367c0-0.0772,0.0586-0.1348,0.1347-0.1348
		c0.1348,0,0.4414-0.0166,0.6934-0.0967c0.4219-0.1142,0.4785-0.5556,0.4785-2.0351v-9.3535c0-0.6524,0.0957-0.8262,0.2305-0.8262
		c0.1914,0,0.4785,0.3252,0.6523,0.499c0.2695,0.2676,2.7285,2.8818,5.3399,5.5703c1.6699,1.7295,3.4785,3.668,3.9941,4.2071
		l-0.1719-8.1817c-0.0195-1.0576-0.1152-1.4248-0.6328-1.5185c-0.3066-0.0596-0.6914-0.0772-0.8262-0.0772
		c-0.1132,0-0.1347-0.0947-0.1347-0.1543c0-0.0967,0.1347-0.1142,0.3281-0.1142c0.7676,0,1.5742,0.0371,1.7656,0.0371
		s0.75-0.0371,1.4414-0.0371c0.1739,0,0.3047,0.0175,0.3047,0.1142c0,0.0596-0.0762,0.1543-0.1933,0.1543
		c-0.0743,0-0.2071,0-0.3985,0.0371c-0.5586,0.1162-0.5996,0.4414-0.5996,1.4219l-0.0371,9.5644
		c0,1.0772-0.0195,1.1739-0.1328,1.1739c-0.1738,0-0.3477-0.1358-1.2891-1c-0.1738-0.1514-2.6133-2.5518-4.3984-4.418
		c-1.959-2.0557-3.8594-4.0518-4.3789-4.6299L302.073,216.4731z"
      />
      <path
        fill="#101010"
        d="M326.4363,213.9595c0,1.5732,0,2.8613,0.0761,3.5342c0.0586,0.4795,0.1348,0.7871,0.6543,0.8642
		c0.2305,0.0371,0.6153,0.0762,0.7696,0.0762c0.1132,0,0.1328,0.0772,0.1328,0.1348c0,0.0761-0.0762,0.1367-0.2696,0.1367
		c-0.9218,0-1.996-0.0606-2.0937-0.0606c-0.0762,0-1.248,0.0606-1.7441,0.0606c-0.1934,0-0.2715-0.04-0.2715-0.1367
		c0-0.0576,0.0371-0.1172,0.1347-0.1172c0.1543,0,0.3457-0.0342,0.5-0.0742c0.3457-0.0772,0.4414-0.4043,0.4981-0.8838
		c0.0762-0.6729,0.0762-1.961,0.0762-3.5342v-6.8594l-2.4375,0.0401c-1.0567,0.0195-1.459,0.1338-1.7129,0.5185
		c-0.1895,0.2881-0.2071,0.4024-0.2657,0.5195c-0.039,0.1338-0.0957,0.1534-0.1738,0.1534c-0.039,0-0.1133-0.0401-0.1133-0.1368
		c0-0.1708,0.3457-1.6328,0.3633-1.7646c0.0391-0.0996,0.1152-0.3076,0.1719-0.3076c0.1152,0,0.2891,0.1338,0.75,0.1914
		c0.4805,0.0566,1.1328,0.0762,1.3242,0.0762h6.3789c0.5586,0,0.9414-0.0371,1.211-0.0762c0.248-0.04,0.4023-0.0772,0.4785-0.0772
		c0.0781,0,0.0781,0.0938,0.0781,0.1934c0,0.4961-0.0606,1.6709-0.0606,1.8613c0,0.1348-0.0742,0.211-0.1523,0.211
		c-0.0762,0-0.1152-0.0567-0.1348-0.2852l-0.0175-0.1738c-0.0567-0.501-0.4219-0.8662-2.0372-0.9033l-2.1132-0.0401V213.9595z"
      />
      <path
        fill="#101010"
        d="M339.8503,211.0766c0-2.5361,0-2.9961-0.0371-3.5147c-0.039-0.5556-0.1367-0.8261-0.6933-0.9033
		c-0.1348-0.0195-0.4239-0.0371-0.5743-0.0371c-0.0586,0-0.1367-0.0771-0.1367-0.1338c0-0.0976,0.0781-0.1347,0.2696-0.1347
		c0.7675,0,1.8613,0.0371,1.9589,0.0371c0.1172,0,3.6485,0.0205,4.0332,0c0.3282-0.0196,0.6368-0.0762,0.7696-0.0938
		c0.0742-0.0224,0.1543-0.0791,0.2285-0.0791c0.0605,0,0.0781,0.0791,0.0781,0.1533c0,0.1172-0.0957,0.3076-0.1348,0.7696
		c-0.0175,0.1543-0.0566,0.8838-0.0976,1.0771c-0.0176,0.0742-0.0742,0.1719-0.1504,0.1719c-0.0977,0-0.1367-0.0772-0.1367-0.211
		c0-0.1171-0.0176-0.3847-0.0938-0.5761c-0.1172-0.2705-0.289-0.3848-1.2129-0.502c-0.2871-0.0371-2.248-0.0566-2.4394-0.0566
		c-0.0742,0-0.0957,0.0566-0.0957,0.1738v4.4385c0,0.1113,0,0.1709,0.0957,0.1709c0.2129,0,2.4394,0,2.8242-0.0371
		c0.4024-0.0401,0.6543-0.0596,0.8281-0.2305c0.1133-0.1172,0.1895-0.2139,0.2481-0.2139s0.0957,0.0401,0.0957,0.1368
		c0,0.0937-0.0957,0.3642-0.1328,0.9003c-0.0371,0.3282-0.0782,0.9415-0.0782,1.0577c0,0.1367-0.039,0.3056-0.1543,0.3056
		c-0.0761,0-0.1132-0.0576-0.1132-0.1513c0-0.1543,0-0.3282-0.0762-0.5362c-0.0606-0.2314-0.2148-0.4248-0.8672-0.5019
		c-0.4609-0.0567-2.2656-0.0762-2.5547-0.0762c-0.0937,0-0.1152,0.0566-0.1152,0.1162v1.3633c0,0.5576-0.0176,2.457,0,2.7852
		c0.039,1.0937,0.3496,1.3046,2.0176,1.3046c0.4414,0,1.248,0,1.6894-0.1738c0.4453-0.1904,0.6738-0.4785,0.7695-1.1308
		c0.0372-0.1944,0.0782-0.2491,0.1719-0.2491c0.0977,0,0.1172,0.1338,0.1172,0.2491c0,0.1162-0.1348,1.2285-0.2305,1.5761
		c-0.1152,0.4219-0.289,0.4219-0.9414,0.4219c-1.2695,0-2.25-0.0371-2.9394-0.0596c-0.709-0.0381-1.1524-0.0381-1.4024-0.0381
		c-0.039,0-0.3652,0-0.7304,0.0206c-0.3262,0.0175-0.709,0.04-0.9805,0.04c-0.1738,0-0.2695-0.04-0.2695-0.1367
		c0-0.0576,0.039-0.1348,0.1367-0.1348c0.1543,0,0.3652-0.0566,0.5176-0.0762c0.3281-0.0576,0.3652-0.3847,0.4433-0.8642
		c0.0957-0.6729,0.0957-1.961,0.0957-3.5342V211.0766z M345.0925,203.7769c0.0977,0.1738,0,0.2334-0.0762,0.2334
		c-0.0566,0-0.25-0.0401-0.5761,0c-0.9414,0.0937-2.2832,0.5361-2.9199,0.8037c-0.1348,0.0576-0.2481,0.0967-0.2872,0.0967
		c-0.039,0-0.0976-0.0391-0.0976-0.1133c0-0.0576,0.041-0.0977,0.1367-0.1543c0.6914-0.4815,2.4785-1.5566,2.6094-1.6328
		c0.3281-0.1914,0.4453-0.251,0.541-0.251c0.0566,0,0.0937,0.0391,0.1914,0.1738
		C344.7488,203.1236,344.8855,203.394,345.0925,203.7769z"
      />
    </g>
    <g />
    <g />
  </svg>
);
