import React, { useEffect, useReducer, useRef } from 'react';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';
import * as R from 'ramda';

import {
  listWineLabels,
  listFormatLabels,
  listPackagingLabels,
  createLabelForWine,
  createLabelForFormat,
  createLabelForPackaging,
} from '../../requests/labels';
import { listWines } from '../../requests/wines';
import { listFormats } from '../../requests/formats';
import { listPackagings } from '../../requests/packagings';
import history from '../../routes/history';
import Autocomplete from '../../theme/components/autocomplete';

const initialState = {
  wineLabels: [],
  formatLabels: [],
  packagingLabels: [],
  page: 1,
  itemsOnPage: 10,
  currentTab: 'wineLabels',
  displayCreateLabel: false,
  wines: undefined,
  formats: undefined,
  packagings: undefined,
  autocompleteValue: undefined,
  filterName: undefined,
};

const reducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'setState':
      return { ...state, [payload.key]: payload.value };
    case 'resetState':
      return initialState;
    default:
      throw new Error();
  }
};

const Labels = ({ cookies }) => {
  const nameInput = useRef();

  const [state, dispatch] = useReducer(reducer, initialState);

  const {
    page,
    itemsOnPage,
    currentTab,
    displayCreateLabel,
    wines,
    formats,
    packagings,
    autocompleteValue,
    filterName,
  } = state;

  const getWineLabelsAction = async () => {
    const { data, errors } = await listWineLabels({
      page,
      itemsOnPage,
      name: filterName,
    });

    if (errors) {
      cookies.remove('token');
      history.push('/signin');
    } else {
      dispatch({
        type: 'setState',
        payload: {
          key: 'wineLabels',
          value: data.paginatedWineLabels,
        },
      });
    }
  };

  const getFormatLabelsAction = async () => {
    const { data, errors } = await listFormatLabels({
      page,
      itemsOnPage,
      name: filterName,
    });

    if (errors) {
      cookies.remove('token');
      history.push('/signin');
    } else {
      dispatch({
        type: 'setState',
        payload: {
          key: 'formatLabels',
          value: data.paginatedFormatLabels,
        },
      });
    }
  };

  const getPackagingLabelsAction = async () => {
    const { data, errors } = await listPackagingLabels({
      page,
      itemsOnPage,
      name: filterName,
    });

    if (errors) {
      cookies.remove('token');
      history.push('/signin');
    } else {
      dispatch({
        type: 'setState',
        payload: {
          key: 'packagingLabels',
          value: data.paginatedPackagingLabels,
        },
      });
    }
  };

  const getWines = async text => {
    const { data, errors } = await listWines({ text });

    if (errors) {
      cookies.remove('token');
      history.push('/signin');
    } else {
      dispatch({
        type: 'setState',
        payload: {
          key: 'wines',
          value: R.take(10, data.filteredWines),
        },
      });
    }
  };

  const getFormats = async text => {
    const { data, errors } = await listFormats({ text });

    if (errors) {
      cookies.remove('token');
      history.push('/signin');
    } else {
      dispatch({
        type: 'setState',
        payload: {
          key: 'formats',
          value: R.take(10, data.filteredFormats),
        },
      });
    }
  };

  const getPackagings = async text => {
    const { data, errors } = await listPackagings({ text });

    if (errors) {
      cookies.remove('token');
      history.push('/signin');
    } else {
      dispatch({
        type: 'setState',
        payload: {
          key: 'packagings',
          value: R.take(10, data.filteredPackagings),
        },
      });
    }
  };

  useEffect(() => {
    getWineLabelsAction();
    getFormatLabelsAction();
    getPackagingLabelsAction();
  }, [page, filterName]);

  const resetSuggestions = () => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'wines',
        value: [],
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'formats',
        value: [],
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'packagings',
        value: [],
      },
    });
  };

  const getSuggestions = () => {
    switch (currentTab) {
      case 'wineLabels':
        return wines;

      case 'formatLabels':
        return formats;

      case 'packagingLabels':
        return packagings;

      default:
        return [];
    }
  };

  const getModalTitle = () => {
    switch (currentTab) {
      case 'wineLabels':
        return 'Créer un label vin';

      case 'formatLabels':
        return 'Créer un label format';

      case 'packagingLabels':
        return 'Créer un label packaging';

      default:
        return 'Créer un label';
    }
  };

  const handleOnTabClick = key => () => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'currentTab',
        value: key,
      },
    });
  };

  const handlePrevious = () => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'page',
        value: page - 1,
      },
    });
  };

  const handleNext = () => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'page',
        value: page + 1,
      },
    });
  };

  const handleDisplayCreateLabel = () => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'displayCreateLabel',
        value: !displayCreateLabel,
      },
    });

    dispatch({
      type: 'setState',
      payload: {
        key: 'autocompleteValue',
        value: undefined,
      },
    });

    resetSuggestions();
  };

  const handleOnAutocompleteChange = text => {
    switch (currentTab) {
      case 'wineLabels':
        getWines(text);
        break;

      case 'formatLabels':
        getFormats(text);
        break;

      case 'packagingLabels':
        getPackagings(text);
        break;

      default:
        break;
    }
  };

  const handleOnAutocompleteClick = item => {
    resetSuggestions();

    dispatch({
      type: 'setState',
      payload: {
        key: 'autocompleteValue',
        value: item,
      },
    });
  };

  const handleCreateLabel = async () => {
    switch (currentTab) {
      case 'wineLabels':
        await createLabelForWine({
          wineUid: autocompleteValue.uid,
          labelName: nameInput.current.value,
        });
        break;

      case 'formatLabels':
        await createLabelForFormat({
          formatUid: autocompleteValue.uid,
          labelName: nameInput.current.value,
        });
        break;

      case 'packagingLabels':
        await createLabelForPackaging({
          packagingUid: autocompleteValue.uid,
          labelName: nameInput.current.value,
        });
        break;

      default:
        break;
    }

    getWineLabelsAction();
    getFormatLabelsAction();
    getPackagingLabelsAction();

    handleDisplayCreateLabel();
  };

  const handleOnNameFilter = e => {
    dispatch({
      type: 'setState',
      payload: {
        key: 'filterName',
        value: e.target.value,
      },
    });
  };

  /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */
  return (
    <div className="container">
      <ul className="tab tab-block">
        <li
          className={
            currentTab === 'wineLabels'
              ? 'tab-item c-hand active'
              : 'tab-item c-hand'
          }
        >
          <a onClick={handleOnTabClick('wineLabels')}>Vin</a>
        </li>
        <li
          className={
            currentTab === 'formatLabels'
              ? 'tab-item c-hand active'
              : 'tab-item c-hand'
          }
        >
          <a onClick={handleOnTabClick('formatLabels')}>Format</a>
        </li>
        <li
          className={
            currentTab === 'packagingLabels'
              ? 'tab-item c-hand active'
              : 'tab-item c-hand'
          }
        >
          <a onClick={handleOnTabClick('packagingLabels')}>Packaging</a>
        </li>
      </ul>
      <div>
        <div className="has-icon-right float-right mt-2">
          <input
            type="text"
            className="form-input"
            onChange={handleOnNameFilter}
          />
          <i className="form-icon icon icon-search" />
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th>nom</th>
            <th>vin</th>
          </tr>
        </thead>
        <tbody>
          {state[currentTab].map(
            ({ name, wine = {}, format = {}, packaging = {} }) => (
              <tr key={`item-${name}`} className="active">
                <td>{name}</td>
                <td>{wine.name || format.name || packaging.name}</td>
              </tr>
            ),
          )}
        </tbody>
      </table>
      <div>
        <button
          type="button"
          className="btn btn-action btn-link btn-lg mt-2"
          onClick={handlePrevious}
          disabled={page === 1}
        >
          <i className="icon icon-arrow-left" />
        </button>
        <button
          type="button"
          className="btn btn-action btn-link btn-lg mt-2"
          onClick={handleNext}
          disabled={state[currentTab].length < itemsOnPage}
        >
          <i className="icon icon-arrow-right" />
        </button>
        <button
          type="button"
          className="btn btn-action btn-primary btn-lg s-circle float-right mt-2"
          onClick={handleDisplayCreateLabel}
        >
          <i className="icon icon-plus" />
        </button>
      </div>
      {displayCreateLabel && (
        <div className="modal active" id="modal-id">
          <div className="modal-overlay" aria-label="Close" />
          <div className="modal-container">
            <div className="modal-header">
              <button
                type="button"
                className="btn btn-clear float-right"
                aria-label="Close"
                onClick={handleDisplayCreateLabel}
              />
              <div className="modal-title h5">{getModalTitle()}</div>
            </div>
            <div className="modal-body" style={{ height: '20rem' }}>
              <div className="content">
                <div className="columns">
                  <div className="column col-12">
                    <input
                      ref={nameInput}
                      className="form-input"
                      placeholder="Nom"
                    />
                  </div>
                  <div className="column col-12 mt-2">
                    {autocompleteValue ? (
                      autocompleteValue.name
                    ) : (
                      <Autocomplete
                        onChange={handleOnAutocompleteChange}
                        onClick={handleOnAutocompleteClick}
                        formatValue={value => value.name}
                        suggestions={getSuggestions()}
                        placeholder="Rechercher..."
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleCreateLabel}
              >
                Créer
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
  /* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/anchor-is-valid, jsx-a11y/no-static-element-interactions */
};

Labels.propTypes = {
  cookies: PropTypes.object.isRequired,
};

export default withCookies(Labels);
