import React, { useEffect, useReducer, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';

import history from '../../routes/history';
import { getCredentials } from '../../requests/users';

import Logo from '../../theme/images/LogoQuancard';

const initialState = {
  credentials: undefined,
  suggestions: [],
  partner: undefined,
  file: undefined,
  worksheets: [],
  header: [],
  body: [],
  defaultFields: [],
  fields: [],
  profiles: [],
  imports: [],
  startingLine: 0,
};

const reducer = (state, { type, payload = {} }) => {
  switch (type) {
    case 'setState':
      return { ...state, [payload.key]: payload.value };
    case 'resetState':
      return initialState;
    default:
      throw new Error();
  }
};

const App = ({ cookies, children }) => {
  const [, dispatch] = useReducer(reducer, initialState);

  const getCredentialsAction = async () => {
    const { data, errors } = await getCredentials();

    if (errors) {
      cookies.remove('token');
      history.push('/signin');
    } else {
      dispatch({
        type: 'setState',
        payload: {
          key: 'credentials',
          value: data.me,
        },
      });
    }
  };

  useEffect(() => {
    getCredentialsAction();
  }, []);

  /**
   * Function removing the token in cookies and redirect to signin
   */
  const handleSignOut = () => {
    cookies.remove('token');

    history.push('/signin');
  };

  /**
   * Function redirecting to the home
   */
  const handleHome = () => {
    history.push('/');
  };

  /**
   * Function redirecting to the admin panel
   */
  const handleAdminPanel = () => {
    history.push('/admin');
  };

  /**
   * If no token in cookies, redirect to signin
   */
  if (!cookies.get('token')) {
    history.push('/signin');
  }

  return (
    <Fragment>
      <div className="navbar m-2">
        <section className="navbar-section" />
        <section className="navbar-center">
          <div className="navbar-brand mr-2" style={{ width: '8rem' }}>
            <Logo />
          </div>
        </section>
        <section className="navbar-section">
          <button
            type="button"
            className="btn btn-primary"
            onClick={handleHome}
          >
            Accueil
          </button>
          <button
            type="button"
            className="btn btn-primary ml-2"
            onClick={handleAdminPanel}
          >
            Admin
          </button>
          <button
            type="button"
            className="btn btn-primary ml-2"
            onClick={handleSignOut}
          >
            Se déconnecter
          </button>
        </section>
      </div>
      <div>{children}</div>
    </Fragment>
  );
};

App.propTypes = {
  cookies: PropTypes.object.isRequired,
  children: PropTypes.object.isRequired,
};

export default withCookies(App);
