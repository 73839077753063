import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Autocomplete from '../../../../theme/components/autocomplete';

const EditImportTable = ({
  header,
  body,
  getSavedWine,
  onAutocompleteChange,
  onValidate,
  onValidateMultiple,
  wines,
  focusedAutocomplete,
  handleOnSelectChange,
  fields,
  defaultFields,
  dispatch,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);

  const handleOnAutocompleteClick = (i, [wineLabel, ...items]) => values => {
    setSelectedValues([...selectedValues, { i, values, items }]);

    dispatch({
      type: 'setState',
      payload: {
        key: 'wines',
        value: [],
      },
    });
  };

  const handleOnValidate = i => () => {
    const selectedValue = selectedValues.find(item => item.i === i);

    onValidate(selectedValue);
  };

  const handleOnValidateMultiple = () => {
    onValidateMultiple(selectedValues);
  };

  return (
    <>
      <button
        type="button"
        className="btn btn-primary ml-2"
        onClick={handleOnValidateMultiple}
      >
        Créer tous les labels
      </button>
      <table className="table" style={{ marginBottom: '10rem' }}>
        <thead>
          <tr className="active">
            <th>
              <p>Label vin à éditer</p>
            </th>
            {header.map((item, i) =>
              i === 0 ? (
                <th key="header-0">
                  <p>Type d&apos;erreur</p>
                </th>
              ) : (
                <th key={`header-${i}`}>
                  <select
                    onChange={handleOnSelectChange(i)}
                    value={fields[i] ? fields[i].code : undefined}
                  >
                    {defaultFields.map(field => (
                      <option key={`option-${field.code}`} value={field.code}>
                        {field.name}
                      </option>
                    ))}
                  </select>
                </th>
              ),
            )}
          </tr>
        </thead>
        <tbody>
          {[header, ...body].map((items, i) => (
            <tr key={`items-${items}-${i}`}>
              {items[0] === 'WINE_LABEL' ? (
                <td>
                  {getSavedWine(i) ? (
                    getSavedWine(i)
                  ) : (
                    <div className="columns">
                      <div className="column col-9">
                        <Autocomplete
                          onChange={onAutocompleteChange(i)}
                          onClick={handleOnAutocompleteClick(i, items)}
                          formatValue={value => value.name}
                          suggestions={wines}
                          suggestionsStyle={{
                            maxHeight: '10rem',
                            overflow: 'scroll',
                          }}
                          placeholder="Rechercher..."
                          open={i === focusedAutocomplete}
                        />
                      </div>
                      <div className="column col-3">
                        <button
                          type="button"
                          className="btn btn-action btn-primary btn-lg s-circle float-right"
                          onClick={handleOnValidate(i)}
                          disabled={!selectedValues.find(item => item.i === i)}
                        >
                          <i className="icon icon-plus" />
                        </button>
                      </div>
                    </div>
                  )}
                </td>
              ) : (
                <td>Aucun</td>
              )}
              {items.map((cell, iterator) => (
                <td key={`body-cell-${cell}-${iterator}`}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
};

EditImportTable.propTypes = {
  getSavedWine: PropTypes.func.isRequired,
  onAutocompleteChange: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired,
  onValidateMultiple: PropTypes.func.isRequired,
  handleOnSelectChange: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  fields: PropTypes.array,
  defaultFields: PropTypes.array,
  header: PropTypes.array,
  body: PropTypes.array,
  wines: PropTypes.array,
  focusedAutocomplete: PropTypes.number,
};

EditImportTable.defaultProps = {
  fields: [],
  defaultFields: [],
  header: [],
  body: [],
  wines: [],
  focusedAutocomplete: 0,
};

export default EditImportTable;
