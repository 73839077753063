import XLSX from 'xlsx';

export const getXlsxWorksheets = file =>
  new Promise(resolve => {
    /* eslint-disable no-undef */
    const reader = new FileReader();
    /* eslint-enable no-undef */
    const rABS = !!reader.readAsBinaryString;
    reader.onload = e => {
      /* Parse data */
      const bstr = e.target.result;
      const wb = XLSX.read(bstr, { type: rABS ? 'binary' : 'array' });
      /* Update worksheets list */
      resolve({ worksheets: wb.SheetNames });
    };
    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });

export const parseXlsxFile = (file, worksheet, startingLine) =>
  new Promise(async resolve => {
    /* eslint-disable no-undef */
    const reader = new FileReader();
    /* eslint-enable no-undef */
    const rABS = !!reader.readAsBinaryString;

    reader.onload = e => {
      const bstr = e.target.result;

      // if raw is false (false by default), the numeric values separated by a comma will be parsed into a wrong value
      // example: 12,34 will become 1234
      const wb = XLSX.read(bstr, {
        type: rABS ? 'binary' : 'array',
        raw: true,
      });

      const ws = wb.Sheets[worksheet];
      const values = XLSX.utils.sheet_to_json(ws, {
        header: 1,
        range: parseInt(startingLine, 10),
        defval: '',
      });

      const [header, ...body] = values;

      resolve({ header, body });
    };

    if (rABS) reader.readAsBinaryString(file);
    else reader.readAsArrayBuffer(file);
  });
