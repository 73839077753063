import gql from 'graphql-tag';

import { call } from '../../utils/api';

const listPackagingsQuery = gql`
  query($text: String!) {
    filteredPackagings(text: $text) {
      uid
      code
      name
    }
  }
`;

export const listPackagings = variables =>
  call({ query: listPackagingsQuery, variables });
