import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';

import history from '../../routes/history';
import { signIn } from '../../requests/users';
import Logo from '../../theme/images/LogoQuancard';

const SignIn = ({ cookies }) => {
  const email = useRef();
  const password = useRef();

  const handleSubmit = async e => {
    e.preventDefault();

    const { data } = await signIn({
      email: email.current.value,
      password: password.current.value,
    });

    if (!data.login.error) {
      cookies.set('token', data.login.data);

      history.push('/');
    }
  };

  return (
    <div className="container flex-centered hero" style={{ height: '100%' }}>
      <div style={{ width: '10rem' }}>
        <Logo />
      </div>
      <div className="panel">
        <div className="panel-header">
          <div className="panel-title">Quancard import</div>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="panel-body columns">
            <div className="column col-12">
              <input
                ref={email}
                style={{ width: '100%' }}
                placeholder="Email"
              />
            </div>
            <div className="column col-12">
              <input
                ref={password}
                type="password"
                className="mt-2"
                style={{ width: '100%' }}
                placeholder="Mot de passe"
              />
            </div>
          </div>
          <div className="panel-footer">
            <button type="submit">Connexion</button>
          </div>
        </form>
      </div>
    </div>
  );
};

SignIn.propTypes = {
  cookies: PropTypes.object.isRequired,
};

export default withCookies(SignIn);
