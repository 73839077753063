import gql from 'graphql-tag';

import { call } from '../../utils/api';

const listPartnersQuery = gql`
  query searchPartners($text: String!) {
    searchPartners(text: $text) {
      uid
      name
    }
  }
`;

export const listPartners = variables =>
  call({ query: listPartnersQuery, variables });
