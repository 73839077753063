import React from 'react';
import PropTypes from 'prop-types';

import Autocomplete from '../../../../theme/components/autocomplete';

const Filters = ({
  partner,
  handleOnAutocompleteChange,
  handleOnAutocompleteClick,
  suggestions,
}) => (
  <div>
    {partner ? (
      <p>{partner.name}</p>
    ) : (
      <Autocomplete
        onChange={handleOnAutocompleteChange}
        onClick={handleOnAutocompleteClick}
        formatValue={value => value.name}
        suggestions={suggestions}
      />
    )}
  </div>
);

Filters.propTypes = {
  handleOnAutocompleteChange: PropTypes.func.isRequired,
  handleOnAutocompleteClick: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  partner: PropTypes.object,
};

Filters.defaultProps = {
  partner: undefined,
};

export default Filters;
