import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import * as R from 'ramda';
import { withCookies } from 'react-cookie';

import { BASE_URL } from '../../../../utils/api';

const states = {
  CREATED: {
    className: 's-circle bg-error',
    title: 'Créé',
  },
  IN_PROGRESS: {
    className: 's-circle bg-warning',
    title: 'En cours',
  },
  COMPLETED: {
    className: 's-circle bg-success',
    title: 'Finalisé',
  },
  ERROR: {
    className: 's-circle bg-error',
    title: 'Erreur',
  },
};

const Imports = ({
  imports,
  cookies,
  handlePrevious,
  handleNext,
  page,
  itemsOnPage,
}) => {
  const data = R.compose(
    R.map(R.values),
    R.map(R.pick(['createdAt', 'date', 'filename', 'uid', 'state'])),
    R.without([null]),
  )(imports);

  const getRow = (content, i, indexData) => {
    const importState = imports[indexData].state;
    const inProgress =
      importState === 'IN_PROGRESS' || importState === 'CREATED';

    switch (i) {
      case 0:
        return dayjs.unix(content).format('DD/MM/YYYY - HH:mm');

      case 1:
        return dayjs.unix(content).format('DD/MM/YYYY');

      case 2:
        return <div>{content}</div>;

      case 3:
        return inProgress ? (
          <span title="Disponible à la fin de l'import" className="ml-1">
            logs
          </span>
        ) : (
          <a
            href={`${BASE_URL}/uploads/import/${content}/import.log?token=${cookies.get(
              'token',
            )}`}
            target="_blank"
            rel="noopener noreferrer"
            className="ml-1"
          >
            logs
          </a>
        );

      case 4:
        return (
          <div
            className={states[content].className}
            title={states[content].title}
            style={{ height: '0.5rem', width: '0.5rem' }}
          />
        );

      default:
        return <div />;
    }
  };

  return (
    <Fragment>
      <div className="has-icon-right float-right" style={{ width: '20rem' }} />
      <table className="table">
        <thead>
          <tr>
            <th>date d&apos;import</th>
            <th>date</th>
            <th>fichier</th>
            <th>logs</th>
            <th>état</th>
            <th>réparation</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, indexData) => (
            <tr key={`item-data-${item[0]}-${indexData}`} className="active">
              {item.map((content, indexItem) => (
                <td key={`item-content-${content}-${indexItem}`}>
                  {getRow(content, indexItem, indexData)}
                </td>
              ))}
              <td>
                {item.pop() === 'ERROR' ? (
                  <a href={`/edit/${item[3]}`} className="ml-1">
                    Réparer
                  </a>
                ) : (
                  "Pas d'erreur"
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div>
        <button
          type="button"
          className="btn btn-action btn-link btn-lg"
          onClick={handlePrevious}
          disabled={page === 1}
        >
          <i className="icon icon-arrow-left" />
        </button>
        <button
          type="button"
          className="btn btn-action btn-link btn-lg"
          onClick={handleNext}
          disabled={imports.length < itemsOnPage}
        >
          <i className="icon icon-arrow-right" />
        </button>
      </div>
    </Fragment>
  );
};

Imports.propTypes = {
  imports: PropTypes.array.isRequired,
  cookies: PropTypes.object.isRequired,
  handlePrevious: PropTypes.func.isRequired,
  handleNext: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  itemsOnPage: PropTypes.number.isRequired,
};

export default withCookies(Imports);
