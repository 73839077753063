import React from 'react';
import PropTypes from 'prop-types';
import { withCookies } from 'react-cookie';

const Table = ({
  header,
  body,
  handleOnSelectChange,
  defaultFields,
  fields,
}) => (
  <table>
    <thead>
      <tr>
        {header.map((item, i) => (
          <th key={`header-${item}`}>
            <p>{item}</p>
            <select
              onChange={handleOnSelectChange(i)}
              value={fields[i] ? fields[i].code : undefined}
            >
              {defaultFields.map(field => (
                <option key={`option-${field.code}`} value={field.code}>
                  {field.name}
                </option>
              ))}
            </select>
          </th>
        ))}
      </tr>
    </thead>
    <tbody>
      <tr>
        {body.map(item => (
          <td key={`body-${item}`}>{item}</td>
        ))}
      </tr>
    </tbody>
  </table>
);

Table.propTypes = {
  handleOnSelectChange: PropTypes.func.isRequired,
  defaultFields: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  header: PropTypes.array,
  body: PropTypes.array,
};

Table.defaultProps = {
  header: [],
  body: [],
};

export default withCookies(Table);
