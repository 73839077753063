import gql from 'graphql-tag';

import { call } from '../../utils/api';

const listWinesQuery = gql`
  query($text: String!) {
    filteredWines(text: $text) {
      uid
      code
      name
    }
  }
`;

export const listWines = variables =>
  call({ query: listWinesQuery, variables });
