import React from 'react';
import PropTypes from 'prop-types';
import { Route, Router, Switch } from 'react-router-dom';

import Main from '../app/main';

import history from './history';
import SignIn from '../app/signin';
import Admin from '../app/admin';
import Menu from '../app/menu';
import EditImportOffers from '../app/edit-import/offers';

const CustomRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (
      <Menu>
        <Component {...props} />
      </Menu>
    )}
  />
);

CustomRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.object, PropTypes.func]).isRequired,
};

export default () => (
  <Router history={history}>
    <Switch>
      <CustomRoute exact path="/" component={Main} />
      <CustomRoute path="/admin" component={Admin} />
      <CustomRoute path="/edit/:uid" component={EditImportOffers} />
      <Route path="/signin" component={SignIn} />
    </Switch>
  </Router>
);
