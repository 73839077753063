import React from 'react';
import ReactDOM from 'react-dom';
import { CookiesProvider } from 'react-cookie';

import './theme/index.css';
import Routes from './routes';
import * as serviceWorker from './serviceWorker';

import './theme/styles/spectre.min.css';
import './theme/styles/spectre-icons.min.css';
import './theme/styles/spectre-exp.min.css';
import './theme/styles/styles.css';

ReactDOM.render(
  <CookiesProvider>
    <Routes />
  </CookiesProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
