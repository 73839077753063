import React, { useState } from 'react';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';

const Autocomplete = ({
  onChange,
  onClick,
  formatValue,
  suggestions,
  delay,
  suggestionsStyle,
  open,
  ...props
}) => {
  const [inputValue, setInputValue] = useState();
  const debounceAction = debounce(onChange, delay);

  const handleOnChange = e => {
    debounceAction(e.target.value);
  };

  const handleOnClick = suggestion => () => {
    onClick(suggestion);
    setInputValue(suggestion.name);
  };

  const handleOnControlledInputChange = () => {
    setInputValue();
  };

  /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
  return (
    <div className="form-autocomplete">
      <div className="form-autocomplete-input form-input">
        {inputValue ? (
          <input
            className="form-input"
            type="text"
            value={inputValue}
            onChange={handleOnControlledInputChange}
          />
        ) : (
          <input
            className="form-input"
            type="text"
            onChange={handleOnChange}
            {...props}
          />
        )}
      </div>

      {suggestions.length !== 0 && open && (
        <ul className="menu" style={suggestionsStyle}>
          {suggestions.map(suggestion => (
            <li
              key={`suggestion-${formatValue(suggestion)}`}
              className="menu-item"
            >
              <div
                className="tile tile-centered c-hand"
                onClick={handleOnClick(suggestion)}
              >
                <div className="tile-content">{formatValue(suggestion)}</div>
              </div>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
  /* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */
};

Autocomplete.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  formatValue: PropTypes.func.isRequired,
  suggestions: PropTypes.array,
  delay: PropTypes.number,
  suggestionsStyle: PropTypes.object,
  open: PropTypes.bool,
};

Autocomplete.defaultProps = {
  suggestions: [],
  delay: 500,
  suggestionsStyle: {},
  open: true,
};

export default Autocomplete;
